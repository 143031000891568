import React from "react";
import "./PriceCardList.css";
import PriceCard from "../PriceCard/PriceCard";
import { PriceContext } from "../../context/PriceContext";

function PriceCardList(props) {
  const price = React.useContext(PriceContext);

  return (
    <section className="price-card-list">
      <h2 className="section__title">Услуги</h2>
      <div className="price-card-list__container">
        {price.map((price) => (
          <PriceCard
            key={`${price.key}p`}
            src={price.src}
            name={price.name}
            price={price.price}
            priceKey={price.location}
            setServiceKey={props.setServiceKey}
            setServiceName={props.setServiceName}
            setServicePrice={props.setServicePrice}
            setOpenCall={props.setOpenCall}
            classNameContainer="price-card__container"
            classNameImg="price-card__img-list"
            classNameBox="price-card__box-list"
            classNameName="price-card__name-list"
            classNameButton="price-card__button-list"
          />
        ))}
      </div>
    </section>
  );
}

export default PriceCardList;
