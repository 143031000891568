import "./App.css";
import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Header from "../Header/Header";
import Main from "../Main/Main";
import { PriceContext, price } from "../../context/PriceContext";
import { gallery, GalleryContext } from "../../context/GalleryContext";
import Gallery from "../Gallery/Gallery";
import { SertContext, sertificate } from "../../context/SertContext";
import Sertificate from "../Sertificate/Sertificate";
import PriceCardList from "../PriceCardList/PriceCardList";
import Contacts from "../Contacts/Contacts";
import Footer from "../Footer/Footer";
import Questions from "../Questions/Questions";
import Prime from "../Prime/Prime";
import Service from "../Service/Service";
import Popup from "../Popup/Popup";
import Form from "../Form/Form";
import Thanks from "../Thanks/Thanks";
import Error from "../Error/Error";
import Seo from "../Seo/Seo";
import { Helmet } from "react-helmet";

function App() {
  const { pathname } = useLocation();
  const [isThanks, setThanks] = React.useState(false);
  const [popupImg, setPopupImg] = React.useState("");
  const [galleryImg, setGalleryImg] = React.useState({});
  const [serviceKey, setServiceKey] = React.useState("1");
  const [isOpenCall, setOpenCall] = React.useState(false);
  const [isBig, setBig] = React.useState(false);
  const [isOpenQuestion, setOpenQuestion] = React.useState(false);
  const [spanQuestion, setSpanQuestion] = React.useState(false);
  const [isOpenPopupImg, setOpenPopupImg] = React.useState(false);
  const [question, setQuestion] = React.useState("");
  function onClose() {
    setOpenQuestion(false);
    setOpenCall(false);
    setOpenPopupImg(false);
    setBig(false);
  }
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  return (
    <div className="App">
      <React.Fragment>
        <Header dimensions={dimensions} setOpenCall={setOpenCall} />
        <Routes>
          <Route
            path="/thank-you"
            element={
              isThanks ? (
                <Thanks>
                  <Helmet>
                    <meta
                      name="description"
                      content="Спасибо за заявку, мы с вами свяжемся"
                    />
                    <title>Спасибо за заявку в Новокомунал</title>
                    <link rel="canonical" href="https://novokomunal.ru/" />
                    <meta name="robots" content="noindex, nofollow" />
                    <meta name="keywords" content="" />
                    <meta
                      property="og:title"
                      content="Спасибо за заявку в Новокомунал"
                    />
                    <meta
                      property="og:description"
                      content="Спасибо за заявку, мы с вами свяжемся"
                    />
                  </Helmet>
                </Thanks>
              ) : (
                <Navigate to="/" />
              )
            }
          ></Route>
          <Route
            path="/contacts"
            element={
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Адреса и телефоны Новокомунал"
                  />
                  <title>Контакты Новокомунал</title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Контакты Новокомунал" />
                  <meta
                    property="og:description"
                    content="Адреса и телефоны Новокомунал"
                  />
                </Helmet>
              </>
            }
          ></Route>
          <Route
            path="/sertificate"
            element={
              <>
                <Main
                  className="main"
                  setOpenCall={setOpenCall}
                  pathname={pathname}
                  title="Лицензии и сертификаты"
                />
                <SertContext.Provider value={sertificate}>
                  <Sertificate
                    dimensions={dimensions}
                    setGalleryImg={setGalleryImg}
                    setPopupImg={setPopupImg}
                    setOpenPopupImg={setOpenPopupImg}
                  />
                </SertContext.Provider>
                <Helmet>
                  <meta
                    name="description"
                    content="Сертификаты и лицензии Новокомунал"
                  />
                  <title>Документы Новокомунал</title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Документы Новокомунал" />
                  <meta
                    property="og:description"
                    content="Сертификаты и лицензии Новокомунал"
                  />
                </Helmet>
              </>
            }
          ></Route>
          <Route
            path="/gallery"
            element={
              <>
                <Main
                  className="main"
                  setOpenCall={setOpenCall}
                  pathname={pathname}
                  title="Наши работы"
                />
                <GalleryContext.Provider value={gallery}>
                  <Gallery
                    dimensions={dimensions}
                    setGalleryImg={setGalleryImg}
                    setPopupImg={setPopupImg}
                    setOpenPopupImg={setOpenPopupImg}
                  />
                </GalleryContext.Provider>
                <Helmet>
                  <meta name="description" content="Фото работ Новокомунал" />
                  <title>Галлерея Новокомунал</title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Галлерея Новокомунал" />
                  <meta
                    property="og:description"
                    content="Фото работ Новокомунал"
                  />
                </Helmet>
              </>
            }
          ></Route>
          <Route
            path="/questions"
            element={
              <>
                <Main
                  className="main"
                  setOpenCall={setOpenCall}
                  pathname={pathname}
                  title="Часто задаваемые вопросы"
                />
                <PriceContext.Provider value={price}>
                  <Questions
                    setOpenQuestion={setOpenQuestion}
                    pathname={pathname}
                  />
                </PriceContext.Provider>
                <Helmet>
                  <meta
                    name="description"
                    content="Ответы Новокомунал на часто задаваемые вопросы"
                  />
                  <title>Часто задаваемые вопросы</title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta
                    property="og:title"
                    content="Часто задаваемые вопросы"
                  />
                  <meta
                    property="og:description"
                    content="Ответы Новокомунал на часто задаваемые вопросы"
                  />
                </Helmet>
              </>
            }
          ></Route>
          <Route
            path="/services/:service"
            element={
              <Service
                setThanks={setThanks}
                dimensions={dimensions}
                setOpenQuestion={setOpenQuestion}
                setGalleryImg={setGalleryImg}
                setPopupImg={setPopupImg}
                setOpenPopupImg={setOpenPopupImg}
                setOpenCall={setOpenCall}
                serviceKey={serviceKey}
              />
            }
          ></Route>
          <Route
            path="/seo/:seo"
            element={
              <Seo
                setThanks={setThanks}
                dimensions={dimensions}
                setOpenQuestion={setOpenQuestion}
                setGalleryImg={setGalleryImg}
                setPopupImg={setPopupImg}
                setOpenPopupImg={setOpenPopupImg}
                setOpenCall={setOpenCall}
              />
            }
          ></Route>
          <Route
            path="/services"
            element={
              <PriceContext.Provider value={price}>
                <Helmet>
                  <meta name="description" content="Список услуг Новокомунал" />
                  <title>Услуги новокомунал</title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="Услуги новокомунал" />
                  <meta
                    property="og:description"
                    content="Список услуг Новокомунал"
                  />
                </Helmet>
                <PriceCardList
                  setOpenCall={setOpenCall}
                  setServiceKey={setServiceKey}
                />
              </PriceContext.Provider>
            }
          ></Route>
          <Route
            path="/"
            element={
              <Prime
                dimensions={dimensions}
                setGalleryImg={setGalleryImg}
                setPopupImg={setPopupImg}
                setOpenPopupImg={setOpenPopupImg}
                setOpenCall={setOpenCall}
                pathname={pathname}
                setServiceKey={setServiceKey}
              >
                <Helmet>
                  <meta
                    name="description"
                    content="Поверка счетчиков воды, тепла. Монтаж и замена счетчиков в Волгограде и Волжском"
                  />
                  <title>
                    Монтаж, замена и поверка счетчиков водоснабжения,
                    теплоснабжения в Волгораде и Волжском
                  </title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="index,follow" />
                  <meta name="keywords" content="" />
                  <meta
                    property="og:title"
                    content="Монтаж, замена и поверка счетчиков водоснабжения, теплоснабжения в Волгораде и Волжском"
                  />
                  <meta
                    property="og:description"
                    content="Поверка счетчиков воды, тепла. Монтаж и замена счетчиков в Волгограде и Волжском"
                  />
                </Helmet>
              </Prime>
            }
          ></Route>
          <Route
            path="*"
            element={
              <Error>
                <Helmet>
                  <meta
                    name="description"
                    content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                  />
                  <title>404</title>
                  <link rel="canonical" href="https://novokomunal.ru/" />
                  <meta name="robots" content="none" />
                  <meta name="keywords" content="" />
                  <meta property="og:title" content="404" />
                  <meta
                    property="og:description"
                    content="Ошибка 404. К сожалению такой страницы не существует. Возможно она была перемещена, или Вы просто неверно указали адрес страницы."
                  />
                </Helmet>
              </Error>
            }
          />
        </Routes>
        <Contacts />
        <Footer />
        <Popup
          name="call"
          title="Заказать звонок"
          isOpen={isOpenCall}
          onClose={onClose}
        >
          <Form
            setThanks={setThanks}
            onClose={onClose}
            name="call"
            title="Заказать звонок"
            button="Заказать звонок"
          ></Form>
        </Popup>
        <Popup
          name="question"
          title="Задать вопрос"
          isOpen={isOpenQuestion}
          question={question}
          onClose={onClose}
        >
          <Form
            setThanks={setThanks}
            name="question"
            title="Задать вопрос"
            button="Задать вопрос"
            onClose={onClose}
            question={question}
          >
            <div className="input__label">
              <span
                className={
                  spanQuestion
                    ? "input__span input__span_active"
                    : "input__span"
                }
              >
                Ваш вопрос
              </span>
              <input
                className="form__input"
                type="text"
                onFocus={() => setSpanQuestion(true)}
                onBlur={(e) =>
                  e.target.value === ""
                    ? setSpanQuestion(false)
                    : setSpanQuestion(true)
                }
                name="question"
                placeholder="Ваш вопрос"
                autoComplete="off"
                maxLength="200"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
              />
            </div>
          </Form>
        </Popup>
        <Popup name="gallery" isOpen={isOpenPopupImg} onClose={onClose}>
          <div className="popup__box">
            <button
              className="button__arrow button__arrow_left"
              onClick={() => {
                popupImg < 1
                  ? setPopupImg(galleryImg.length - 1)
                  : setPopupImg(popupImg - 1);
              }}
            ></button>
            <img
              className={isBig ? "popup__img popup__img_big" : "popup__img"}
              src={isOpenPopupImg ? galleryImg[popupImg].src : ""}
              alt="img"
              style={
                isBig
                  ? {
                      maxWidth: window.screen.width,
                      maxHeight: window.screen.width,
                      cursor: "zoom-out",
                    }
                  : {}
              }
              onClick={() => setBig(!isBig)}
            />
            <button
              className="button__arrow button__arrow_right"
              onClick={() => {
                popupImg > galleryImg.length - 2
                  ? setPopupImg(0)
                  : setPopupImg(popupImg + 1);
              }}
            ></button>
          </div>
        </Popup>
      </React.Fragment>
    </div>
  );
}

export default App;
