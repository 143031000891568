import "./Error.css";
import React from "react";

function Error(props) {
  return (
    <section className="error">
      {props.children}
      <h1 className="error__title">Ошибка 404</h1>
      <p className="error__subtitle">Этой страницы не существует</p>
    </section>
  );
}

export default Error;
