import React from "react";
import "./Sertificate.css";
import { SertContext } from "../../context/SertContext";

function Sertificate(props) {
  const sertificate = React.useContext(SertContext);
  const [buttonArrow, setButtonArrow] = React.useState(true);
  const [slideStart, setSlideStart] = React.useState(0);
  const [slideEnd, setSlideEnd] = React.useState(
    props.dimensions.width <= 768 ? sertificate.length : 4
  );
  function slidePlus() {
    if (slideEnd >= sertificate.length) {
      setSlideStart(0);
      setSlideEnd(4);
    } else {
      setSlideStart(slideStart + 1);
      setSlideEnd(slideEnd + 1);
    }
  }
  function slideMinus() {
    if (slideStart <= 0) {
      setSlideStart(sertificate.length - 4);
      setSlideEnd(sertificate.length);
    } else {
      setSlideStart(slideStart - 1);
      setSlideEnd(slideEnd - 1);
    }
  }
  function isOpen(e) {
    props.setOpenPopupImg(true);
    props.setPopupImg(e.target.src);
  }
  React.useEffect(() => {
    sertificate.length <= 4 ? setButtonArrow(true) : setButtonArrow(false);
  }, [sertificate.length]);

  return (
    <section className="sertificate">
      {props.children}
      <div className="sertificate__container">
        {sertificate.slice(slideStart, slideEnd).map((sert) => (
          <div key={`${sert.key}f`} className="sertificate__box">
            <img
              onClick={(e) => {
                isOpen(e);
                props.setPopupImg(sert.index);
                props.setGalleryImg(sertificate);
              }}
              className="sertificate__img"
              src={sert.src}
              alt="foto"
            />
            <p className="sertificate__text">{sert.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Sertificate;
