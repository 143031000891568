import React from "react";
import "./Form.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Form(props) {
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [isKey, setKey] = React.useState("");
  const [spanName, setSpanName] = React.useState(false);
  const [spanTel, setSpanTel] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const navigate = useNavigate();

  const regex = /['a-z''-''/'':']/g;
  const regex4 = /['а-я']/g;

  function change(e) {
    // e.target.value.length <= 1 ? setPhone('8' + e.target.value) : setPhone(`${e.target.value.replace(/\s+/g, '').substring(0, 1)} ${e.target.value.replace(/\s+/g, '').substring(1, 4)} ${e.target.value.replace(/\s+/g, '').substring(4, 7)} ${e.target.value.replace(/\s+/g, '').substring(7, e.target.value.replace(/\s+/g, '').length)}`)
    isKey === 8
      ? setPhone(e.target.value.replace(/\s+/g, ""))
      : e.target.value.length <= 2 ||
        regex.test(e.target.value) ||
        regex4.test(e.target.value)
      ? setPhone("+7")
      : regex.test(e.target.value) || regex4.test(e.target.value)
      ? setPhone("+7")
      : setPhone(
          `${e.target.value
            .replace(/\s+/g, "")
            .substring(0, 2)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(2, 5)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(5, 8)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(8, 10)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(10, e.target.value.replace(/\s+/g, "").length)}`
        );
  }
  function onSubmit(e) {
    e.preventDefault();
    let formData = new FormData();
    if (props.question) {
      formData.append("question", props.question);
    }
    formData.append("name", name);
    formData.append("tel", phone);
    formData.append("title", props.title);
    axios({
      method: "POST",
      url: "./send.php",
      // url: 'http://test.xxx/send.php',
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        if (response.data.result === "success") {
          props.setThanks(true);
          navigate("/thank-you");
          props.onClose();
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  return (
    <form className={`form form__${props.class}`} id="form">
      <h4 className="form__title">{props.title}</h4>
      <p className="form__subtitle">{props.subtitle}</p>
      <div className="form__box">
        <div className="input__label">
          <span
            className={
              spanName ? "input__span input__span_active" : "input__span"
            }
          >
            Имя
          </span>
          <input
            className="form__input"
            type="text"
            name="name"
            placeholder="Имя"
            minLength="2"
            maxLength="30"
            value={name}
            onFocus={() => setSpanName(true)}
            onBlur={(e) =>
              e.target.value === "" ? setSpanName(false) : setSpanName(true)
            }
            onChange={(e) => {
              setName(e.target.value);
              e.target.value.length !== "" && phone.length === 16
                ? setDisabled(false)
                : setDisabled(true);
            }}
            autoComplete="off"
            required={true}
          />
        </div>
        <div className="input__label">
          <span
            className={
              spanTel ? "input__span input__span_active" : "input__span"
            }
          >
            Телефон
          </span>
          <input
            className="form__input"
            type="tel"
            name="tel"
            onFocus={() => setSpanTel(true)}
            onBlur={(e) =>
              e.target.value === "" ? setSpanTel(false) : setSpanTel(true)
            }
            placeholder="Телефон"
            value={phone}
            onClick={(e) =>
              e.target.value.length !== 0 ? <></> : setPhone("+7")
            }
            minLength="16"
            maxLength="16"
            onKeyDown={(e) => {
              setKey(e.keyCode);
            }}
            onChange={(e) => {
              change(e);
              name !== "" && e.target.value.length > 11
                ? setDisabled(false)
                : setDisabled(true);
            }}
            autoComplete="off"
            required={true}
          />
        </div>
        {props.children}
        <button
          className={
            isDisabled ? "form__button form__button_disabled" : "form__button"
          }
          onClick={(e) => onSubmit(e)}
          type="submit"
          value="Отправить"
          disabled={isDisabled}
        >
          {props.button}
        </button>
      </div>
    </form>
  );
}

export default Form;
