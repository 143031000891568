import "./Main.css";

function Main(props) {
  return (
    <section className="main">
      <p className={`${props.className}__subtitle`}>
        {props.pathname === "/"
          ? "Аккредитация № 567887654345678 ОТ 01.01.2001г."
          : props.title}
      </p>
      {props.className === "service" ? null : (
        <h1 className={`${props.className}__title`}>
          Установка, замена, поверка приборов учета
        </h1>
      )}

      <button className="main button" onClick={() => props.setOpenCall(true)}>
        <div className="button__bg">
          <p className="button__text">Оставить заявку</p>
        </div>
      </button>
    </section>
  );
}

export default Main;
