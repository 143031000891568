import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__text">ИП Немчук Иван Игоревич</p>
      <p className="footer__text">
        Юридический адрес: Волгоградская обл. г. Волгоград
      </p>
      <p className="footer__text">ИНН: 344812489146 ОГРНИП: 322344300070033</p>
    </footer>
  );
}

export default Footer;
