import "./Thanks.css";
import bg from "../../images/main.png";
import { useNavigate } from "react-router-dom";

function Thanks() {
  const navigate = useNavigate();
  return (
    <section className="thanks">
      <img className="thanks__img" src={bg} alt="foto" />
      <h2 className="thanks__title">Спасибо за заявку</h2>
      <p className="thanks__text">Скоро мы вам перезвоним</p>
      <button
        type="button"
        className="section__button section__button_blue"
        onClick={() => navigate("/")}
      >
        вернуться на главную
      </button>
    </section>
  );
}

export default Thanks;
