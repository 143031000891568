import React from "react";
import "./Price.css";
import PriceCard from "../PriceCard/PriceCard";
import { PriceContext } from "../../context/PriceContext";
import { Link } from "react-router-dom";

function Price(props) {
  const price = React.useContext(PriceContext);

  return (
    <section className="price">
      <h2 className="section__title">Услуги</h2>
      <div className="price__box">
        <div className="price__container">
          {price.slice(0, 4).map((price) => (
            <PriceCard
              setOpenCall={props.setOpenCall}
              key={`${price.key}price`}
              priceKey={price.location}
              src={price.src}
              name={price.name}
              price={price.price}
              setServiceKey={props.setServiceKey}
            />
          ))}
        </div>
      </div>
      <Link
        className="section__button section__button_white price__button"
        to="./services"
      >
        Все услуги
      </Link>
    </section>
  );
}

export default Price;
