import "./Service.css";
import React from "react";
import Main from "../Main/Main";
import { PriceContext, price } from "../../context/PriceContext";
import { SertContext, sertificate } from "../../context/SertContext";
import Sertificate from "../Sertificate/Sertificate";
import Questions from "../Questions/Questions";
import Documents from "../Documents/Documents";
import { useParams, Link } from "react-router-dom";
import Form from "../Form/Form";
import { Helmet } from "react-helmet";

function Service(props) {
  const { service } = useParams();
  const [serviceName, setServiceName] = React.useState("");
  const [servicePrice, setServicePrice] = React.useState("");
  const [serviceAbout, setServiceAbout] = React.useState([]);

  React.useEffect(() => {
    setServiceName(price.find((p) => p.location === service).name);
    setServicePrice(price.find((p) => p.location === service).price);
    setServiceAbout(price.find((p) => p.location === service).about);
  }, [service]);
  return (
    <section className="service">
      <Helmet>
        <meta name="description" content={serviceName} />
        <title>{serviceName}</title>
        <link rel="canonical" href="https://novokomunal.ru/" />
        <meta name="robots" content="index,follow" />
        <meta name="keywords" content="" />
        <meta property="og:title" content={serviceName} />
        <meta property="og:description" content={serviceName} />
      </Helmet>
      <div className="service__title-box">
        <div className="service__link-box">
          <Link className="service__link" to="/">
            Главная/
          </Link>
          <Link className="service__link" to="/services">
            Услуги/
          </Link>
          <p className="service__link">{serviceName}</p>
        </div>
        <h2 className="service__title">{serviceName}</h2>
      </div>
      <div className="service_box">
        {serviceAbout.map((s) =>
          s.type === "title" ? (
            <h2
              key={`${serviceAbout.indexOf(s)}titleserv`}
              className="service__subtitle"
            >
              {s.content}
            </h2>
          ) : s.type === "text" ? (
            <p
              key={`${serviceAbout.indexOf(s)}textserv`}
              className="service__text"
            >
              {s.content}
            </p>
          ) : s.type === "img" ? (
            <img
              key={`${serviceAbout.indexOf(s)}imgserv`}
              className="service__img"
              src={s.content}
            />
          ) : null
        )}
      </div>
      <div className="service__price-box">
        {price
          .find((p) => p.location === service)
          .pricelist.map((p) => (
            <div
              key={`${price
                .find((p) => p.location === service)
                .pricelist.indexOf(p)}priselist`}
              className="service__price-container"
            >
              <img
                className={`${props.classNameImg} service__price-img`}
                src={price.find((p) => p.location === service).src}
                alt="Услуга"
              />
              <p className="service__price-name">{p.name}</p>
              <p className="service__price-price">{p.price}</p>
              <button
                type="button"
                className={`${props.classNameButton} section__button section__button_blue`}
                onClick={() => props.setOpenCall(true)}
              >
                заказать
              </button>
            </div>
          ))}
      </div>
      <Form
        onSubmit={props.onSubmit}
        setThanks={props.setThanks}
        class="services"
        title={price.find((p) => p.location === service).form}
        subtitle={price.find((p) => p.location === service).formsub}
        button="Заказать"
      />
      {/* <SertContext.Provider value={sertificate}>
        <Sertificate
          dimensions={props.dimensions}
          setGalleryImg={props.setGalleryImg}
          setOpenPopupImg={props.setOpenPopupImg}
          setPopupImg={props.setPopupImg}
        >
          <h2 className="section__title">Лицензии и сертификаты</h2>
        </Sertificate>
      </SertContext.Provider>
        <Documents
          dimensions={props.dimensions}
          setGalleryImg={props.setGalleryImg}
          setOpenPopupImg={props.setOpenPopupImg}
          setPopupImg={props.setPopupImg}
          serviceKey={service}
        >
          <h2 className="section__title">
            Предоставляем полный пакет документов
          </h2>
        </Documents> */}
      <PriceContext.Provider value={price}>
        <Questions
          pathname="service"
          setOpenQuestion={props.setOpenQuestion}
          serviceKey={service}
        >
          <h2 className="section__title">Часто задаваемые вопросы</h2>
        </Questions>
      </PriceContext.Provider>
    </section>
  );
}

export default Service;
