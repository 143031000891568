import React from "react";
import sert1 from "../images/setrificate/attestat.png";

export const SertContext = React.createContext();

export const sertificate = [
  {
    key: "1",
    index: 0,
    src: `${sert1}`,
    text: "Аттестат аккредитации",
  },
];
