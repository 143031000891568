import "./Contacts.css";
import telefon from "../../images/icon/telefon.svg";
import adress from "../../images/icon/adress.svg";
import email from "../../images/icon/email.svg";
import React from "react";

function Contacts() {
  React.useEffect(() => {
    var script = document.createElement("script");
    script.defe = true;
    script.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ad5ecb7b902fae23f20d2e2b1896b11d3fe6976572acc88b901a0b7a1cce20963&amp;id=mymap&amp;lang=ru_RU&amp;scroll=true'";
    script.type = "text/javascript";
    setTimeout(function () {
      document.getElementById("mymap").appendChild(script);
    }, 3000);
  }, []);
  return (
    <section className="contacts">
      <h2 className="section__title">Контакты</h2>
      <div className="contacts__container">
        <div className="contacts__box">
          <div className="contacts__title-box">
            <img className="contacts__icon" src={telefon} alt="Телефон" />
            <p className="contacts__title">Телефоны:</p>
          </div>
          <a className="contacts__text" href="tel:+79001826478">
            +7 (900) 182-64-78
          </a>
          <a className="contacts__text" href="tel:+79001743894">
            +7 (900) 174-38-94
          </a>
        </div>
        <div className="contacts__box">
          <div className="contacts__title-box">
            <img className="contacts__icon" src={adress} alt="Адреса" />
            <p className="contacts__title">Адреса офисов:</p>
          </div>
          <p className="contacts__text contacts__text_adress">
            ул. Пархоменко 8
          </p>
        </div>
        <div className="contacts__box">
          <div className="contacts__title-box">
            <img className="contacts__icon" src={email} alt="E-mail" />
            <p className="contacts__title">E-mail:</p>
          </div>
          <a className="contacts__text" href="mailto:vcw34@mail.ru">
            novokomunal@yandex.ru
          </a>
        </div>
      </div>
      <div className="contacts__map" id="mymap"></div>
    </section>
  );
}

export default Contacts;
