import React from "react";
import "./PriceCard.css";
import { useNavigate } from "react-router-dom";

function PriceCard(props) {
  const navigate = useNavigate();

  return (
    <div className={`${props.classNameBox} price-card__box`}>
      <div className={`${props.classNameContainer}`}>
        <img
          className={`${props.classNameImg} price-card__img`}
          src={props.src}
          alt="Услуга"
        />
        <div className="price-card__text-box">
          <p className="price-card__name">{props.name}</p>
          <p className="price-card__price">{props.price}</p>
        </div>
      </div>
      <button
        type="button"
        className={`${props.classNameButton} section__button section__button_blue`}
        onClick={() => props.setOpenCall(true)}
      >
        заказать
      </button>
      <button
        type="button"
        className={`${props.classNameButton} section__button section__button_white`}
        onClick={() => {
          props.setServiceKey(props.priceKey);
          navigate(`/services/${props.priceKey}`);
        }}
      >
        подробнее
      </button>
    </div>
  );
}

export default PriceCard;
