import React from "react";
import "./Feedback.css";
import { FeedBackContext } from "../../context/FeedBackContext";
import avatar from "../../images/avatar.png";

function Feedback(props) {
  const feedback = React.useContext(FeedBackContext);
  const [slideStart, setSlideStart] = React.useState(0);
  const [slideEnd, setSlideEnd] = React.useState(
    props.dimensions.width <= 768
      ? feedback.length
      : props.dimensions.width <= 1063
      ? 2
      : 3
  );
  function slidePlus() {
    if (slideEnd >= feedback.length) {
      setSlideStart(0);
      setSlideEnd(3);
    } else {
      setSlideStart(slideStart + 1);
      setSlideEnd(slideEnd + 1);
    }
  }

  function slideMinus() {
    if (slideStart <= 0) {
      setSlideStart(feedback.length - 3);
      setSlideEnd(feedback.length);
    } else {
      setSlideStart(slideStart - 1);
      setSlideEnd(slideEnd - 1);
    }
  }

  function OpenFeed(e) {
    console.log(
      (e.target.parentElement.children[1].className =
        "feedback__text feedback__text_close")
    );
    if (e.target.innerText === "Показать больше") {
      e.target.innerText = "Скрыть";

      e.target.parentElement.children[1].className = "feedback__text";
    } else {
      e.target.innerText = "Показать больше";
      e.target.parentElement.children[1].className =
        "feedback__text feedback__text_close";
    }
  }

  return (
    <section className="feedback">
      <h2 className="section__title">Отзывы</h2>
      <div className="feedback__container">
        <button
          type="button"
          className="button__arrow button__arrow_left"
          onClick={() => slideMinus()}
        ></button>
        {feedback.slice(slideStart, slideEnd).map((feed) => (
          <div key={`${feed.key}f`} className="feedback__box">
            <div className="feedback__people-box">
              <img className="feedback__img" src={avatar} alt="foto" />
              <p className="feedback__name">{feed.name}</p>
            </div>
            <p className="feedback__text feedback__text_close">
              {feed.feedback}
            </p>
            <button
              id={`${feed.key}feed`}
              className="feedback__button"
              onClick={(e) => {
                OpenFeed(e);
              }}
            >
              Показать больше
            </button>
          </div>
        ))}
        <button
          type="button"
          className="button__arrow button__arrow_right"
          onClick={() => slidePlus()}
        ></button>
      </div>
    </section>
  );
}

export default Feedback;
