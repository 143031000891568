import React from "react";
import "./Team.css";
import { ComandaContext } from "../../context/ComandaContext";

function Team() {
  const team = React.useContext(ComandaContext);
  return (
    <section className="team">
      <h2 className="section__title">Наша команда</h2>
      <div className="team__container">
        {team.map((worker) => (
          <div key={`${worker.key}w`} className="team__box">
            <img className="team__img" src={worker.src} alt={worker.name} />
            <p className="team__name">{worker.name}</p>
            <p className="team__work">{worker.work}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Team;
