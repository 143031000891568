import React from "react";
import comanda1 from "../images/comanda/comanda1.jpg";
import comanda2 from "../images/comanda/comanda2.jpg";
import comanda3 from "../images/comanda/comanda3.jpg";
import comanda4 from "../images/comanda/comanda4.jpg";

export const ComandaContext = React.createContext();

export const comanda = [
  {
    key: "1",
    name: "Бахтин Евгений",
    work: "Мастер-сантехник",
    src: `${comanda1}`,
  },
  {
    key: "2",
    name: "Деркунский Роман",
    work: "Мастер-сантехник",
    src: `${comanda2}`,
  },
  {
    key: "3",
    name: "Титаренко Сергей",
    work: "Метролог-сантехник",
    src: `${comanda3}`,
  },
  {
    key: "4",
    name: "Чернов Сергей",
    work: "Метролог-сантехник",
    src: `${comanda4}`,
  },
];
