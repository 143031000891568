import Main from "../Main/Main";
import { PriceContext, price } from "../../context/PriceContext";
import Price from "../Price/Price";
import { comanda, ComandaContext } from "../../context/ComandaContext";
import Team from "../Team/Team";
import { gallery, GalleryContext } from "../../context/GalleryContext";
import Gallery from "../Gallery/Gallery";
import { feedback, FeedBackContext } from "../../context/FeedBackContext";
import Feedback from "../Feedback/Feedback";
import { SertContext, sertificate } from "../../context/SertContext";
import Sertificate from "../Sertificate/Sertificate";
import { Outlet } from "react-router-dom";

function Prime(props) {
  return (
    <section className="prime">
      {props.children}
      <Main
        className="main"
        setOpenCall={props.setOpenCall}
        pathname={props.pathname}
      />
      <PriceContext.Provider value={price}>
        <Price
          setOpenCall={props.setOpenCall}
          setServiceKey={props.setServiceKey}
        />
      </PriceContext.Provider>
      <ComandaContext.Provider value={comanda}>
        <Team />
      </ComandaContext.Provider>
      <GalleryContext.Provider value={gallery}>
        <Gallery
          dimensions={props.dimensions}
          setGalleryImg={props.setGalleryImg}
          setPopupImg={props.setPopupImg}
          setOpenPopupImg={props.setOpenPopupImg}
        >
          <h2 className="section__title">Наши работы</h2>
        </Gallery>
      </GalleryContext.Provider>
      <FeedBackContext.Provider value={feedback}>
        <Feedback dimensions={props.dimensions} />
      </FeedBackContext.Provider>
      <SertContext.Provider value={sertificate}>
        <Sertificate
          dimensions={props.dimensions}
          setGalleryImg={props.setGalleryImg}
          setPopupImg={props.setPopupImg}
          setOpenPopupImg={props.setOpenPopupImg}
        >
          <h2 className="section__title">Лицензии и сертификаты</h2>
        </Sertificate>
      </SertContext.Provider>
    </section>
  );
}

export default Prime;
