import "./Popup.css";

function Popup(props) {
  return (
    <section
      onClick={(e) => e.currentTarget === e.target && props.onClose()}
      className={`popup popup_type_${props.name} ${
        props.isOpen ? "popup_on" : ""
      }`}
    >
      <div
        className="popup__container popup__container_form"
        method="GET"
        action="#"
        name={`${props.name}`}
      >
        <button
          className="popup__close"
          type="button"
          onClick={props.onClose}
        ></button>
        {props.children}
      </div>
    </section>
  );
}

export default Popup;
