import "./Questions.css";
import React from "react";
import up from "../../images/icon/up.svg";
import down from "../../images/icon/down.svg";
import { PriceContext } from "../../context/PriceContext";
import { useLocation } from "react-router-dom";

function Questions(props) {
  const { pathname } = useLocation();
  const price = React.useContext(PriceContext);
  const [isOpen, setOpen] = React.useState(false);
  const [isId, setId] = React.useState("0");

  return (
    <section className="questions" id="questions">
      {props.children}
      <div className="questions__main-box">
        {pathname === "/questions"
          ? price.map((q) =>
              q.faq.map((question) => (
                <div
                  key={`${question.key}q`}
                  className="questions__box"
                  id={question.key}
                  onClick={() => {
                    if (isOpen && isId !== question.key) {
                      setOpen(true);
                      setId(question.key);
                    } else {
                      setOpen(!isOpen);
                      setId(question.key);
                    }
                  }}
                >
                  <div className="questions__container">
                    <p className="questions__question">{question.question}</p>
                    <img
                      className="questions__icon"
                      src={isOpen & (question.key === isId) ? up : down}
                      alt="icon"
                    />
                  </div>
                  {question.answer.map((a) => (
                    <p
                      key={`${q.faq.indexOf(question)}q2`}
                      className={
                        isOpen && question.key === isId
                          ? "questions__answer questions__answer_active"
                          : "questions__answer"
                      }
                    >
                      {a}
                    </p>
                  ))}
                </div>
              ))
            )
          : price
              .find((p) => p.location === props.serviceKey)
              .faq.map((question) => (
                <div
                  key={`${question.key}q`}
                  className="questions__box"
                  id={question.key}
                  onClick={() => {
                    if (isOpen && isId !== question.key) {
                      setOpen(true);
                      setId(question.key);
                    } else {
                      setOpen(!isOpen);
                      setId(question.key);
                    }
                  }}
                >
                  <div className="questions__container">
                    <p className="questions__question">{question.question}</p>
                    <img
                      className="questions__icon"
                      src={isOpen & (question.key === isId) ? up : down}
                      alt="icon"
                    />
                  </div>
                  {question.answer.map((a) => (
                    <p
                      key={`${question.answer.indexOf(a)}q2`}
                      className={
                        isOpen && question.key === isId
                          ? "questions__answer questions__answer_active"
                          : "questions__answer"
                      }
                    >
                      {a}
                    </p>
                  ))}
                </div>
              ))}

        <div className="questions__info">
          <p className="questions__text">
            Не нашли ответа? Заполните форму, а мы свяжемся с Вами в ближайшее
            время и ответим на все вопросы
          </p>
          <button
            className="section__button section__button_blue"
            onClick={() => props.setOpenQuestion(true)}
          >
            Задать вопрос
          </button>
        </div>
      </div>
    </section>
  );
}

export default Questions;
