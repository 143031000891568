import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../images/icon/logo.svg";

function Header(props) {
  const [isMenu, setMenu] = React.useState(false);
  return (
    <header className="header">
      <div className="header__logo-box">
        <button
          className="header__menu-button header__menu-button_active"
          type="button"
          onClick={() => setMenu(!isMenu)}
        ></button>
        <a className="header__home" href="/">
          <img className="header__logo" src={logo} alt="ВЦВ" />
          <p className="header__logo-text">Новокомунал</p>
        </a>
      </div>
      <nav
        className={
          props.dimensions.width <= 1070
            ? isMenu
              ? "header__menu header__menu_active"
              : "header__menu"
            : "header__menu header__menu_active"
        }
      >
        <Link className="header__list" to="./" onClick={() => setMenu(false)}>
          Главная
        </Link>
        <Link
          className="header__list"
          to="./services"
          onClick={() => setMenu(false)}
        >
          Услуги
        </Link>
        <Link
          className="header__list"
          to="./gallery"
          onClick={() => setMenu(false)}
        >
          Наши работы
        </Link>
        <Link
          className="header__list"
          to="./sertificate"
          onClick={() => setMenu(false)}
        >
          Лицензии
        </Link>
        <Link
          className="header__list"
          to="./contacts"
          onClick={() => setMenu(false)}
        >
          Контакты
        </Link>
        <Link
          className="header__list"
          to="./questions"
          onClick={() => setMenu(false)}
        >
          Вопросы
        </Link>
      </nav>
      <div className="header__container">
        <div className="header__tel-box">
          <a className="header__tel" href="tel:+79001826478">
            +7 (900) 182-64-78
          </a>
          <a className="header__tel" href="tel:+79001743894">
            +7 (900) 174-38-94
          </a>
        </div>
        <button
          className="header__button"
          onClick={() => props.setOpenCall(true)}
        >
          Заказать звонок
        </button>
      </div>
    </header>
  );
}

export default Header;
