import React from "react";

export const FeedBackContext = React.createContext();

export const feedback = [
  {
    key: 1,
    name: "Денис Люсин",
    feedback:
      "Сервис отличный, все супер. Мастер приехал вовремя, очень вежливый. Все сделал без проблем и быстро. Никаких подводных камней и стоимость работ соответствовала оговорённой с менеджером по телефону. По документам все объяснил и собрал весь пакет для передачи данных в управляющую компанию. В управляющей компании вопросов по документам не было. Буду ещё обращаться и советую всем. Спасибо большое за отличный сервис.",
  },
  {
    key: 2,
    name: "Александр Гущин",
    feedback:
      "К мастеру претензий никаких, четко, вежливо, грамотно, быстро. Есть вопрос по организации: Когда делал заявку обещали что перезвонят минимум за час до назначенного времени, подтвердят приход мастера и уточнят время. За час никто не перезвонил, за полчаса тоже.. Попытка дозвониться по городскому телефону ни к чему ни привела, телефон сбрасывал. Пообщался с консультантом на сайте, он сообщил что мастер наберет за 15-30 минут до прихода, телефон мастера не дали. Услуга была назначена с 13:00 до 14:00 Пришлось сидеть и ждать. Мастер перезвонил в 13:57. В 14:30 все было сделано! Потерян час времени, а ведь решить эту проблему очень просто!",
  },
  {
    key: 3,
    name: "Ольга Минаева",
    feedback:
      "Добрый день! Заказывала поверку счётчиков в этой компании. Осталось довольна выполненной работой. Всё хорошо и при заказе чётко всё мастер объяснил заявку принял. Так же приехал мастер вовремя в назначенное время, обходительный чётко знающий своё дело человек , всё документы всё в порядке, в УК приняли документы . Цена приемлима. Спасибо компании. Даже советую своим соседям эту компанию. Уже многие жильцы обращались и довольны выполненим работы. Спасибо!",
  },
  {
    key: 4,
    name: "Александра С.",
    feedback:
      "Всем довольна. Мастер приехал в удобное время, приятный в коммуникации. Поменял счетчики, объяснил что и как. Счетчики красного и синего цвета, и у меня сложилась ассоциация, что красный счётчик на горячую воду, а синий на холодную, тогда как установлены они были по-другому, поэтому возник вопрос. Я позвонила в кол-центр службы, мне быстро ответили, перезвонил мастер, объяснил, что счетчики универсальные. Вся коммуникация была вежливая, быстрая и четкая. Стоимость работ полностью соответствовала оговорённой ранее цене. Спасибо!",
  },
  {
    key: 5,
    name: "Анастасия Владимировна",
    feedback:
      "Позвонила, договорились о дате и времени. Мастер пришел в назначенное время. Нормальный мужик, по квартире в бахилах ходил. По работе мастера вообще претензий нет. ",
  },
  {
    key: 6,
    name: "Давид Бабаян",
    feedback:
      "Нужно было заменить счетчик воды. Первый раз позвонил в субботу, спросил все, узнал цену, гарантию. Потом почитал про фирму, проверил имеется ли действительно лицензия у них. Сравнил с другими и решил все таки воспользоваться их услугами. Звоню заново в понедельник, а цена выросла на 100 рублей. Не критично, но немного неприятно. Но уже потеряно время на их проверку, решил уже не тратить время на поиск другой фирмы из за 100 рублей. Договорился по телефону. Мастер приехал на час раньше, все сделал быстро, отдал документы, цена соответствовала. И не спрашивая забрал старый счетчик. Хорошо я увидел, и сказал что он мне нужен.",
  },
  {
    key: 7,
    name: "Алексей И.",
    feedback:
      "Нужна была срочная поверка, договорились на следующий день с 9 до 11. Мастер позвонил в 10-30, приехал ближе к 12. Поверку, заполнение документов провёл быстро и качественно. Вежливый молодой человек. От другой компании раньше приезжал очень странный товарищ. К накладкам по времени я была готова, было важно, чтоб сделали в этот день. Все отлично.",
  },
  {
    key: 8,
    name: "Татьяна Оградина",
    feedback:
      "Делала поверку в этой компании, осталась очень довольна, мастер пришел в назначенное время, всё быстро и грамотно сделал, выдал документы и объяснил дальнейшие действия.Очень вежливый и тактичный молодой человек! Спасибо Вам за Вашу работу!",
  },
  {
    key: 9,
    name: "Вероника Е.",
    feedback:
      "Все отлично, оставляла заявку на поверку 4х водосчетчиков, мастер прибыл точно в оговорённое время, быстро все поверил. Все обьяснил, все документы выдал . Спасибо",
  },
  {
    key: 10,
    name: "Дмитрий Калинченко",
    feedback:
      "Мастер пришёл вовремя. Все четко, аккуратно, вежливо. Дважды пользовалась услугами по поверке водосчетчиков в данной компании (для разных квартир). Оба раза все было прекрасно. Рекомендую!",
  },
  {
    key: 11,
    name: "Андрей Чёрный",
    feedback:
      "Спасибо отличная организация проводила у меня поверку счетчиков воды. Мастер приехал вовремя, был вежлив, аккуратен, даже одел бахилы, быстрое обслуживание и комфорт в быстроте действий. Спасибо рекомендую.",
  },
  {
    key: 12,
    name: "Алексей Трусов",
    feedback:
      "Я скажу прямо и коротко! Сервис на высоте, Мастер отработал на 110%-очень вежливый, всё объяснял пока работал, очень быстро заполнил все документы и ушёл! Если бы все такие были-мы жили бы в другой мтране! Браво! Всем сюда! Пожелание к руководству-не снижайте обороты, совершенствуйтесь ибо нет предела совершенству!!! Молодцы!!!",
  },
  {
    key: 13,
    name: "Маргоша С.",
    feedback:
      "Мастер приехал вовремя, позвонил за полчаса, как и оговаривалось. Все сделал быстро, чётко, заполнил все документы, объяснил что куда относить. Учитывая, что проверка делалась у ооочень возрастных пенсионеров, то это дополнительный плюс мастеру. Стоимость совпала с той, которую назвали при оформлении заказа - 800 руб за счётчик, без каких либо накруток. Это уже второе обращение в эту компанию. Молодцы!",
  },
  {
    key: 14,
    name: "Оксана Ч.",
    feedback:
      "Ребята большие молодцы (один мастер Роман, второго парня имя не запомнила к сожалению)! 👍🏻 24.08.21г. приехали в назначенный интервал времени. Попросила не звонить в домофон, т.к. маленький ребенок - все запомнили, как подъехали позвонили! Были в масках и в бахилах, работали быстро и ЧИСТО, что не маловажно! Дали даже рекомендации по счетчикам. Рекомендую однозначно",
  },
  {
    key: 15,
    name: "Александр И.",
    feedback:
      "Отличная компания поверку родителям и себе сделал тут, оперативно, качественно и недорого, скидки на сайте действительные, Жилищник документы от Доступного сервиса принимает на Ура, рекомендую стопроцентово.",
  },
  {
    key: 16,
    name: "Лариса Иванова",
    feedback:
      "Сервис на высоте, отличный уровень обслуживания, мастера профессионалы своего дела, очень внимательные операторы, курируют заявки с момента оформления и до момента исполнения.",
  },
  {
    key: 17,
    name: "Алла И.",
    feedback:
      "Вызывала мастера на замену счетчика, приехал отличный мастер, все заменил, все четко и доступно. Хорошая фирма всем рекомендую. Осталась довольна полностью. Работают действительно профессионалы!!!",
  },
  {
    key: 18,
    name: "Наталья Стукалова",
    feedback:
      "Все сделали по людски. Позвонил мастер, приехал вовремя, подключил свое оборудование для поверки, через шланг от лейки душа, все быстро! Все бумаги в порядке, печати реальные. ",
  },
  {
    key: 19,
    name: "Ирина",
    feedback:
      "Нужна была проверка счетчиков воды, из многих компаний выбрали эту, вежливые операторы, четко ответили на все вопросы, поэтому остановились на ней. Буквально через день пришел специалист по нашей заявке. Мастер вежливый, надел бахилы, все проверил с помощью оборудования быстро и качественно, заполнил все необходимые бумаги для управляющей компании и для нас. Компания имеет лицензию, поэтому проблем с подачей документов не будет, очень довольны.",
  },
];
