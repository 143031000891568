import React from "react";
import act from "../images/documents/1.jpg";
import dog1 from "../images/documents/2.jpg";
import sert from "../images/documents/4.png";
import price1 from "../images/price/price1.png";

export const SeoContext = React.createContext();

export const seos = [
  {
    key: "1",
    location: "poverka-priborov-ucheta-vodi-dzerzhinskii",
    name: "Поверка приборов учета воды в Дзержинском районе Волгограда ",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Компания Новокомунал – быстрая и доступная по цене поверка счетчиков воды в Дзержинском районе Волгограда",
        type: "text",
      },
      {
        content:
          "Если вы проживаете в Дзержинском районе Волгограда и все еще находитесь в поиске специализированной компании, то воспользуйтесь услугами компании Новокоммунал, оказывающую услуги по приему и обработке заявок на поверку счетчиков во всех районах Волгограда. Спешите стать постоянным клиентом компании Новокомунал, чтобы навсегда забыть о всех проблемах, связанных со счетчиками воды.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1a",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2a",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3a",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1a",
        question:
          "Для чего проводится поверка счетчиков воды в Дзержинском Волгограда?",
        answer: [
          "В механических водомерах, к которым относятся бытовые приборы учета воды, крыльчатка изо дня в день подвергается воздействию потока воды. В результате лопасти крыльчатки постепенно изнашиваются, наблюдается смещение опор вала крыльчатки, изгиб самого вала и сужение проточной части счетчика. В измерительной части счетчика тоже возможно появление недочетов: проскальзывание магнитной муфты, появление лифта в шестеренчатой передаче, затрудненное вращение роликов счетного барабана. Совокупный износ деталей счетчика приводит к появлению сторонних «тикающих» шумов и ухудшению точности его работы, вплоть до недопустимого увеличения погрешности измерений.",
          "Государство кровно заинтересовано в объективности показаний приборов учета и на законодательном уровне устанавливает необходимость проведения поверки −технической процедуры для определения соответствия счетчиков метрологическим характеристикам. Если говорить более понятным житейским языком, то поверка заключается в сравнении показаний поверяемого счетчика с показаниями эталонного прибора.",
        ],
      },
      {
        key: "2a",
        question:
          "Как часто должна проводиться поверка счетчиков воды в Дзержинском районе Волгограда?",
        answer: [
          "Ухудшение точности работы счетчика, как правило, происходит постепенно и растягивается на годы. Поэтому поверку водомеров проводят раз в несколько лет по истечении межповерочного интервала (МИ), определяемого производителем прибора. МИ счетчиков горячей воды составляет 4-6 лет, а счетчиков холодной воды - 6 лет. Укороченный МИ счетчиков горячей воды объясняется более быстрым износом деталей под воздействием горячей воды.",
          "МИ, как и дата заводской поверки,  указывается в паспорте на прибор. Важно знать, что отсчёт МИ проводится с даты заводской поверки, но никак не с даты приобретения или установки счетчика. Если вы, допустим, приобрели и установили счетчик ГВС, пролежавший до этого 3 года на складе, то уже через 3 года ваша управляющая компания заставит вас проводить его поверку.",
        ],
      },
      {
        key: "3a",
        question:
          "Чем грозит просрочка поверки водосчетчиков в Дзержинском Волгограда?",
        answer: [
          "Если у вас давно истёк МИ, но вы продолжаете платить по показаниям счетчика, то ваша ресурсоснабжающая компания обязательно произведёт вам перерасчёт оплаты за весь период просрочки− чем длительней просрочка, тем большую сумму лишних рублей вам придётся заплатить. Расчет оплаты будет проводиться в следующем порядке:",
          "1.	первый месяц просрочки −по среднемесячным показаниям за последние полгода;",
          "2.	начиная со 2- го месяца просрочки−по нормативам с повышающим коэффициентом 1,5.",
        ],
      },
      {
        key: "4a",
        question:
          "Как проводится поверка счетчиков воды в Дзержинском Волгограда?",
        answer: [
          "Поверка счетчиков воды проводится двумя способами: с демонтажом и без демонтажа счетчика. В первом случае демонтированный счетчик доставляется в метрологическую лабораторию, где проходит детальное обследование и поверку. Далее признанный исправным счетчик доставляется на квартиру и  устанавливается на место. Для реализации данного способа на практике абоненту необходимо дважды вызывать мастера ресурсоснабжающей компании, дважды выезжать в лабораторию, ожидать несколько дней результатов поверки. При этом за время отсутствия счетчика абоненту придётся оплачивать воду по нормативам.",
          "Вариант поверки на дому без демонтажа счетчика привлекает своей высокой скоростью и минимальным участием абонента в организации её проведения. Ему необходимо лишь подать заявку в специализированную компанию, в назначенный день и час встретить метролога, и уже через час получить заключение о текущем состоянии счетчика.",
          "Поверка на дому проводится с помощью высокоточного поверочного прибора УПСЖ 5ПМ, используемого в качестве эталона. Прибор подключают к крану на линии поверяемого счетчика, пропускают через него небольшое количество воды (6-10 литров) и сравнивают показания счетчика с показаниями эталонного прибора. При расхождении показаний меньше 3% счетчик считается прошедшим поверку, при большем расхождении счетчик признается вышедшим из строя и подлежит замене.",
        ],
      },
      {
        key: "5a",
        question:
          "Как оформляются результаты поверки счетчиков воды в Дзержинском районе Волгограда?",
        answer: [
          "Ещё в недалёком прошлом регистрация результатов поверки проводилась по предоставлению в управляющую компанию пакета бумажных документов Но, начиная с 24 сентября 2020 года, действуют новые правила поверки, направленные на упрощение документооборота и борьбу с мошенническими компаниями. Теперь для всех заинтересованных организаций значимой является только электронная запись в ФГИС Аршин, а бумажные документы уже не имеют юридической силы.",
          "По новым правилам на оформление электронной записи даётся 40 рабочих дней после проведения поверки. Длительное отсутствие записи в системе Аршин может стать поводом для ваших разногласий с ресурсоснабжающей компанией, поэтому мы рекомендуем получать пакет бумажных документов.",
          "По желанию всех владельцев частных домов и абонентов многоквартирных домов, имеющих прямые договора с ресурсоснабжающей организацией ООО «Концессии водоснабжения», мы проведем регистрацию результатов поверки.",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "2",
    location: "poverka-priborov-ucheta-vodi-centralnii",
    name: "Поверка приборов учета воды в Центральном районе Волгограда ",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Счетчики воды относятся к категории простых и надежных измерительных приборов, рассчитанных на долгий срок эксплуатации. В идеальных условиях квартирные счетчики способны проработать 12 и более лет без потери точности измерений. Но на самом деле, водопроводная вода не идеальна по составу и содержит в себе химически активные вещества, абразивы и загрязнения. К тому же в системе часты гидроудары, избыточное давление и сверхвысокие температуры. Синергетическое воздействие названных факторов приводит к преждевременному износу деталей счетчика, появлению посторонних звуков и, в конечном итоге, к ухудшению точности измерений.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1b",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2b",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3b",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1b",
        question:
          "Для чего проводится поверка счетчиков воды в Центральном Волгограда?",
        answer: [
          "Счетчики воды относятся к категории простых и надежных измерительных приборов, рассчитанных на долгий срок эксплуатации. В идеальных условиях квартирные счетчики способны проработать 12 и более лет без потери точности измерений. Но на самом деле, водопроводная вода не идеальна по составу и содержит в себе химически активные вещества, абразивы и загрязнения. К тому же в системе часты гидроудары, избыточное давление и сверхвысокие температуры. Синергетическое воздействие названных факторов приводит к преждевременному износу деталей счетчика, появлению посторонних звуков и, в конечном итоге, к ухудшению точности измерений.",
          "Государство в целом и все участники цепочки водоснабжения заинтересованы в объективности показаний счетчика, поэтому поверка водомеров на предмет их соответствия метрологическим требованиям является обязательной процедурой.",
        ],
      },
      {
        key: "2b",
        question:
          "Когда проводится поверка счетчиков воды в Центральном Волгограда?",
        answer: [
          "Необходимость проведения поверки прописана в Федеральном Законе № 102-ФЗ от 26 июня 2008 года и в Постановлении Правительства РФ № 354 от 6 мая 2011 года. Периодичность проведения поверки определяется межповерочным интервалом (МИ), который устанавливает завод-производитель, исходя из конструкции прибора, использованных материалов и условий эксплуатации. Отсчет МИ производится с даты заводской поверки, и этот факт необходимо учитывать, стараясь приобретать счетчик, только выпущенный в продажу.",
          "МИ тахометрических (механических) счетчиков отечественных производителей сегодня составляет 4-6 лет, а импортных счетчиков – до 15 лет. Для контроля сроков плановой поверки вы можете использовать паспорт на прибор, ЕПД, систему Аршин.",
          "До сих пор мы говорили о плановой поверке, связанной с МИ. В редких случаях возникает необходимость проведения и досрочной поверки водомеров. Это может быть связано:",
          "- с утерей документов на счетчик;",
          "- с обнаружением явных несоответствий в работе счетчика;",
          "- с механическим повреждением счетчика и нарушением его целостности;",
          "- нарушением поверочного клейма или со срывом пломб;",
          "- с длительным простоем счетчика в «сухом» состоянии;",
          "Вы также можете провести досрочную поверку по собственному желанию, если сомневаетесь в точности работы счетчика.",
        ],
      },
      {
        key: "3b",
        question:
          "Что будет при просрочке поверки счетчиков воды в Центральном Волгограда?",
        answer: [
          "В соответствии с Постановлением Правительства РФ от 06.05.2011 № 354 индивидуальный прибор учета (ИПУ) с истекшим МИ считается вышедшим из строя или отсутствующим, и, как следствие, его показания считаются недействительными. За все время просрочки начисления вам будут проводиться по следующей схеме:",
          "1.	первый месяц просрочки −по среднемесячным показаниям за последние полгода;",
          "2.	начиная со 2- го месяца просрочки−по нормативам с повышающим коэффициентом 1,5.",
          "Так как нормативы в разы превышают реальное потребление воды, то при длительной просрочке поверки вы рискуете потерять десятки тысяч рублей!",
        ],
      },
      {
        key: "4b",
        question:
          "Как проводится поверка счетчиков воды в Центральном Волгограда? ",
        answer: [
          "В любом крупном городе имеются подразделения государственной метрологической службы, а также частные аккредитованные метрологические лаборатории, в которых вы можете провести поверку своего ИПУ. Но для этого вам необходимо снять счетчик и доставить его в лабораторию, а после проведения поверочных работ выполнить все действия в обратном порядке. Как можно видеть, лабораторный вариант поверки не очень удобен, так как требует вашего личного участия, согласования времени монтажа/демонтажа ИПУ с управляющей компанией, дополнительных расходов на транспорт и услуги сантехника, длительного ожидания результатов поверки.",
          "С учетом названных неудобств большинство абонентов отдают предпочтение поверке на дому без необходимости демонтажа счетчика. Поверка без снятия занимает не более 30-50 минут времени, проводится за один визит метролога в любой выбранный вами день и час.",
          "Процедура поверки на дому проводится с помощью высокоточного эталонного прибора (УПСЖ 5ПМ) и включает следующие шаги:",
          "1.	Внешний осмотр ИПУ на предмет целостности пломб, отсутствия механических повреждений и следов внешнего несанкционированного воздействия.",
          "2.	Подключение эталонного прибора к водопроводу.",
          "3.	Пропускание небольшого количества воды с фиксацией показаний ИПУ и эталонного прибора. Повторение измерений несколько раз для определения средних значений.",
          "4.	Выдача заключения о пригодности счетчика: если расхождение в показаниях не превышает 3%, то ИПУ считается исправным, в противном случае ИПУ подлежит замене.",
          "Как показывает практика, более половины поверяемых счетчиков не проходят первую плановой поверку и подлежат замене. Учитывая эту статистику, мастера-метрологи всегда берут с собой на выезд счетчики лучших отечественных производителей (ЭКОНОМ, ВСКМ, ЭКОМЕРА, ПУЛЬС, ITELMA) на случай необходимости оперативной замены счетчика.",
        ],
      },
      {
        key: "5b",
        question:
          "Как регистрируются результаты поверки счетчиков воды в Центральном Волгограда?",
        answer: [
          "Цифровизация постепенно охватывает систему ЖКХ, упрощая учёт и контроль энергоресурсов. Она коснулась и порядка регистрации результатов поверки. Теперь, начиная с 24 сентября 2020 года, введен электронный документооборот и только электронная запись в ФГИС Аршин является юридическим свидетельством прохождения поверки.  Право внесения этой записи имеют только метрологи аккредитованных компаний. При этом запись должна быть оформлена в течение 40 рабочих дней со дня проведения поверки.",
          "Несмотря на то, что бумажные документы теперь не имеют юридической силы, мы рекомендуем регистрировать их в вашей ресурсоснабжающей компании.",
        ],
      },
    ],
    pricelist: [
      {
        key: "1b",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2b",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3b",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "3",
    location: "poverka-priborov-ucheta-vodi-voroshilovskii",
    name: "Поверка приборов учета воды в Ворошиловском районе Волгограда ",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Квартирные водомеры относятся к категории расходомеров механического принципа действия и отличаются относительной простотой конструкции и надежностью работы. Однако под ежедневным воздействием  далеко не идеальной по составу воды счетчики постепенно изнашиваются, что неизбежно приводит к ухудшению точности показаний.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1c",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2c",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3c",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1c",
        question:
          "Зачем нужна поверка счётчиков воды в Ворошиловском районе Волгограда?",
        answer: [
          "Квартирные водомеры относятся к категории расходомеров механического принципа действия и отличаются относительной простотой конструкции и надежностью работы. Однако под ежедневным воздействием  далеко не идеальной по составу воды счетчики постепенно изнашиваются, что неизбежно приводит к ухудшению точности показаний.",
          "Как показывает практика, почти половина всех квартирных индивидуальных приборов учета (ИПУ) воды уже через несколько лет работы перестают давать правильные показания. Для обеспечения объективности показаний ИПУ и необходимо проведение периодической поверки – процедуры проверки их соответствия метрологическим требованиям.",
        ],
      },
      {
        key: "2c",
        question:
          "Когда должна проводиться поверка счётчиков воды в Ворошиловском районе Волгограда?",
        answer: [
          "Плановая поверка ИПУ является обязательной процедурой (№ 102-ФЗ от 26.06.2008г. «Об обеспечении единства измерений» и Постановление Правительства Российской Федерации № 354 от 06.05.2011г.) и проводится при приближении окончания межповерочного интервала (МИ).",
          "МИ устанавливается заводом-изготовителем и является одной из главных эксплуатационных характеристик водомера. В течение действия МИ показания ИПУ принимаются как достоверные всеми участниками процесса холодного и горячего водоснабжения.",
          "МИ отечественных счетчиков холодной и горячей воды, как правило, составляет 6 лет, также все еще встречаются  счетчики ГВС с 4-х летним МИ.",
          "Важно помнить, что отсчет МИ проводится с даты заводской поверки, а не с даты покупки или установки счетчика.",
          "Может так случиться, что по причине заводского брака или неправильного монтажа счетчик начнет сильно «врать» задолго до окончания МИ. Также досрочная поверка должна проводиться при утрате документов на счетчик, при срыве пломб, после длительного отсутствия воды в системе, при случайном повреждении счетчика. ",
          "При возникновении внештатной ситуации вы должны незамедлительно сообщить об этом в вашу ресурсоснабжающую компанию и координировать свои действия с ней. Все затраты на проведение плановой/внеплановой поверки возлагаются на вас.",
        ],
      },
      {
        key: "3c",
        question:
          "Чем грозит просрочка поверка счётчиков воды в Ворошиловском районе Волгограда?",
        answer: [
          "Помимо паспорта на счетчик, срок проведения очередной поверки указываются в ЕПД и в системе Аршин. В конечном итоге, просто невозможно не знать о нем и просрочка поверки – это целиком ваша халатность, за которую придется платить дополнительным рублем.",
          "ИПУ с просроченным МИ считается отсутствующим и в соответствии с п.59 и 60 Постановления Правительства РФ №354 от 6 мая 2011 г вам в 1-ый месяц просрочки придется платить по среднемесячным показаниям счетчика, а далее – по региональным нормативам, умноженным на коэффициент 1,5. Учитывая, что нормативы, как минимум, в 2 раза превышают фактическое потребление воды, то вы заплатите, как минимум, в 3 раза больше, чем по показаниям ИПУ.",
        ],
      },
      {
        key: "4c",
        question:
          "Как проводится поверка счётчиков воды в Ворошиловском районе Волгограда?",
        answer: [
          "Поверку квартирного ИПУ можно провести на лабораторном стенде, но для этого вам необходимо снять и доставить счетчик в метрологическую лабораторию, а после поверки, выполнить все действия в обратном порядке. Демонтаж/ монтаж ИПУ− достаточно хлопотное мероприятие, требующее согласования с управляющей компанией и оплаты услуг сантехника. Добавьте к этому транспортные расходы и многодневное ожидание  результатов поверки − в конечном итоге поверка в лаборатории выливается в целое мероприятие, требующее вашего постоянного участия. По этой причине основным вариантом поверки в Ворошиловском районе является сегодня поверка на дому без снятия ИПУ.",
          "Поверка на дому привлекает высокой скоростью проведения− не более 30-60 минут на один счетчик. При этом вы вправе сами выбрать удобный для вас день визита метролога.",
          "Поверка на дому проводится через сравнение показаний эталонного прибора УПСЖ 5ПМ с показаниями поверяемого ИПУ. Допустимым считается 3% расхождение показаний, при большем расхождении счетчик не допускается к установке и подлежит замене. ",
        ],
      },
      {
        key: "5c",
        question:
          "Как регистрируются результаты поверка счётчиков воды в Ворошиловском районе Волгограда?",
        answer: [
          "По новым правилам поверки от 24 сентября 2020 года регистрация результатов поверки проводится на сайте ФГИС Аршин через оформление соответствующей электронной записи метрологом аккредитованной компании. Эта запись должна быть внесена не позднее 40 дней со дня проведения поверки и является единственным и достаточным свидетельством ее проведения.",
          "Несмотря на то, что бумажные документы носят теперь только информационный характер, мы все же советуем регистрировать результаты поверки в «Концессии Водоснабжения» на случай сбоев в системе Аршин. По вашему желанию мы сделаем это за вас, если только вы проживаете в частном секторе или являетесь абонентом многоквартирного дома, имеющим прямой договор с ООО «Концессия Водоснабжения».",
          "Мы быстро примем и обработаем вашу заявку, проконтролируем внесение записи в ФГИС Аршин, а при необходимости окажем содействие в регистрации результатов поверки в ООО «Концессия Водоснабжения».",
        ],
      },
    ],
    pricelist: [
      {
        key: "1c",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2c",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3c",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "4",
    location: "poverka-priborov-ucheta-vodi-kirovskii",
    name: "Поверка приборов учета воды в Кировском районе Волгограда ",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Счетчики воды хоть и относятся к измерительным приборам длительного действия, но за время эксплуатации все же постепенно изнашиваются, теряя при этом точность своих измерений. Данный факт нельзя не учитывать, так как он нарушает финансовые взаимоотношения между абонентами и ресурсоснабжающей организацией. При заниженных показаниях счетчика терпит убытки «Концессия Водоснабжения», при завышенных – абонент.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1d",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2d",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3d",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1d",
        question:
          "Что такое поверка счетчиков воды в Кировском районе Волгограда?",
        answer: [
          "Счетчики воды хоть и относятся к измерительным приборам длительного действия, но за время эксплуатации все же постепенно изнашиваются, теряя при этом точность своих измерений. Данный факт нельзя не учитывать, так как он нарушает финансовые взаимоотношения между абонентами и ресурсоснабжающей организацией. При заниженных показаниях счетчика терпит убытки «Концессия Водоснабжения», при завышенных – абонент.",
          "Для подтверждения точности работы счетчика и проводится поверка – сравнение его показаний с показаниями эталона.  Обязательность проведения периодической поверки прописана в Законе №102 «Об обеспечении единства измерений» и Постановлением Правительства №354, при этом все расходы по проведению поверки несет абонент.",
        ],
      },
      {
        key: "2d",
        question:
          "Как часто должна проводиться поверка счетчиков воды в Кировском районе Волгограда?",
        answer: [
          "В соответствии с Постановлением Правительства №354 периодическая поверка индивидуальных приборов учета (ИПУ) должна проводиться в соответствии с межповерочным интервалом (МИ). Причем, отсчет первого межповерочного интервала ведется с даты проведения заводской поверки, которая также указывается в паспорте на прибор.",
          "МИ отечественных ИПУ составляет 4-6 лет – для счетчиков горячей воды, и 6 лет – для счетчиков холодной воды. Меньший МИ счетчика ГВС обусловлен более интенсивным воздействием горячей воды на износ его деталей.",
        ],
      },
      {
        key: "3d",
        question:
          "Почему важно соблюдать сроки поверки счетчиков воды в Кировском районе Волгограда?",
        answer: [
          "Водомер с истекшим МИ считается вышедшим из строя и ресурсоснабжающая организация не принимает к расчету его показания. За просрочку поверки вам придется платить значительно больше, так как за первый месяц просрочки начисления вам будут проводиться по среднемесячным показаниям счетчика, а начиная со 2-го месяца – по нормативам потребления в Волгоградской обл. с применением повышающего коэффициента.",
        ],
      },
      {
        key: "4d",
        question:
          "Как проводится поверка счетчиков воды в Кировском районе Волгограда?",
        answer: [
          "Существует два способа поверки ИПУ:",
          "1.	С демонтажом счетчика и проведением поверочных работ в метрологической лаборатории.",
          "2.	Без демонтажа по месту установки счетчика.",
          "Поверка в лаборатории предусматривает детальное обследование счетчика, но при этом является более хлопотной для абонента, так как требует вызова сантехника для демонтажа/монтажа счетчика, поездок в лабораторию, ожидания в течение нескольких дней результатов поверки. При этом помимо дополнительных транспортных затрат абоненту придется оплачивать воду по нормативам на время отсутствия счетчика.",
          "Поверка на дому не предусматривает никаких манипуляций с самим счетчиком, проводится всего в течение 30-60 минут за один визит метролога, не требует дополнительных затрат и согласования времени поверки с управляющей компанией. Выигрывая по скорости проведения, стоимости и простоте организации, поверка на дому имеет один недостаток: вероятность выхода счетчика из строя до окончания следующего МИ, так как в этом случае не проводится детальное обследование счетчика.",
          "Абоненты, как правило, предпочитают не тратить свое время и нервы, и выбирают поверку на дому, процедура которой включает:",
          "1.	Внешний осмотр счетчика на предмет проверки сохранности пломб, вращения индикаторной стрелки, отсутствия внешних повреждений.",
          "2.	Подключение эталонного прибора к линии поверяемого счетчика.",
          "3.	Настройку эталонного прибора.",
          "4.	Пропускание через прибор нескольких литров воды и сравнение его показаний с показаниями счетчика.",
          "5.	Фиксацию расхождений в показаниях счетчика и прибора.",
          "6.	Повторение несколько раз шагов 4 и 5.",
          "7.	Определение среднего значения расхождений.",
          "8.	Выдача заключения о пригодности счетчика. Допустимым считается 3% расхождение в показаниях.",
          "9.	Выдачу по требованию абонента пакета бумажных документов (Договора, Акта и Свидетельства о поверке).",
        ],
      },
      {
        key: "5d",
        question: "Новые правила поверки счетчиков воды",
        answer: [
          "С 24 сентября 2020 года действуют новые правила поверки, согласно которым только электронная запись в системе Аршин является официальным подтверждением проведения поверки, при этом право внесения этой записи имеют только метрологи аккредитованных компаний. Бумажные же документы, по которым до этого проводилась регистрация результатов поверки, теперь утратили свою юридическую значимость.",
          "Несмотря на то, что бумажные документы носят теперь только информационный характер, мы все же советуем регистрировать результаты поверки в «Концессии Водоснабжения» на случай сбоев в системе Аршин. По вашему желанию мы сделаем это за вас, если только вы проживаете в частном секторе или являетесь абонентом многоквартирного дома, имеющим прямой договор с ООО «Концессия Водоснабжения».",
          "Мы быстро примем и обработаем вашу заявку, проконтролируем внесение записи в ФГИС Аршин, а при необходимости окажем содействие в регистрации результатов поверки в ООО «Концессия Водоснабжения».",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "5",
    location: "poverka-priborov-ucheta-vodi-volzhskii",
    name: "Поверка приборов учета воды в Волжском",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волжском вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Установка индивидуальных приборов учёта (ИПУ) позволяет в 2 и более раз уменьшить наши платежи за воду, так как мы начинаем более экономно подходить к её расходу, а в большей мере − из-за явно завышенных значений действующих нормативов потребление воды. ",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1e",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2e",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3e",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1e",
        question: "Для чего нужна поверка счетчиков воды в Волжском?",
        answer: [
          "Установив однажды ИПУ в квартире, мы по умолчанию соглашаемся с его показаниями, считая их точными. На самом же деле, счетчик со временем изнашивается, и его показания теряют первоначальную точность. В конечном итоге, точность показаний ИПУ может ухудшиться настолько, что перестанут отображать реальный расход воды. По этой причине необходимо периодически проверять текущую точность работы ИПУ, что и достигается проведением поверки.",
        ],
      },
      {
        key: "2e",
        question: "Когда проводится поверка счетчиков воды в Волжском?",
        answer: [
          "Плановая поверка ИПУ должна проводиться за счет абонента в сроки, определяемые межповерочным интервалом (МИ) − временным интервалом, в течение которого точность измерений прибора считается приемлемой.",
          "МИ устанавливается производителем прибора и является одной из его главных временных характеристик. В настоящее время, как правило, МИ современных моделей счетчиков холодной и горячей воды составляет 6 лет.",
          "Отсчёт МИ ведётся с даты заводской (первичной) поверки, что очень важно помнить при покупке счетчика. Например, приобретая залежавшийся на складе счетчик, вам придётся проводить поверку не через положенные 6 лет, а за минусом времени, который счетчик пролежал на складе.",
          "Возможны случаи, когда вам придётся поверять счетчик и до окончания МИ. Наиболее частый из них − явно ложные показания ИПУ по причине заводского брака, неточности монтажа, тяжёлых условий эксплуатации и т.д. Также внеплановая поверка требуется при утере документов на ИПУ, срыве пломб, нарушении поверочного клейма, после длительного отсутствия воды в системе.",
          "При возникновении вышеназванных случаев вам необходимо сообщить о них в ресурсоснабжающую отранизацию и как можно быстрее провести поверку, так как с момента уведомления и до даты проведения внеочередной поверки начисления за воду вам будут проводиться по нормативам.",
        ],
      },
      {
        key: "3e",
        question: "Чем грозит просрочка поверки счетчиков воды в Волжском?",
        answer: [
          "Важно соблюдать сроки проведения поверки, так как показания просроченного ИПУ считаются недействительными и начисления за воду проводятся по нормативам, а если более точно, то по следующей схеме:",
          "1. первый месяц просрочки −по среднемесячным показаниям за последние полгода;",
          "2. начиная со 2- го месяца просрочки−по нормативам потребления Волгоградской области с повышающим коэффициентом 1,5.",
        ],
      },
      {
        key: "4e",
        question: "Как проводится поверка счетчиков воды в Волжском?",
        answer: [
          "Поверка в Волжском большей частью проводится на дому без снятия ИПУ. Такой вариант поверки привлекает быстротой проведения и минимальным участием абонента в его реализации. А вот для поверки в лаборатории абоненту необходимо снять и доставить счетчик в лабораторию, после нескольких дней ожидания забрать счетчик и вновь установить его на место. В условиях нашей постоянной занятости, да и просто из лени, мало кто из нас согласится на столь длительный и хлопотный вариант поверки. Да и по деньгам получается накладно − помимо оплаты самих поверочных работ, необходимо ещё нести затраты на транспорт и оплату услуг сантехника для демонтажа/монтажа счетчика.",
          "Поверка ИПУ на дому проводится с помощью переносной поверочной установки (УПСЖ 3ПМ) через сравнение ее показаний с показаниями ИПУ при разных напорах воды. Сравниваемые показания должны отличаться не более, чем на 3 %, и в этом случае счетчик считается исправным и годным для дальнейшей работы. Если же различие в показаниях превышает 3%, то счетчик должен быть демонтирован и заменён на новый.",
        ],
      },
      {
        key: "5e",
        question:
          "Как регистрируются результаты поверки счетчиков воды в Волжском?",
        answer: [
          "Результаты поверки заносятся на сайт ФГИС Аршин и любое заинтересованные лицо (УК, Концессия Водоснабжения, вы сами) может в любое время просмотреть состояние ваших ИПУ. Важно отметить, что право внесения записи в систему Аршин имеют только аккредитованные компании, при этом на внесение записи дается 40 дней со дня проведения поверки.",
          "По новым правилам поверки от 24 сентября 2020 года эта электронная запись заменяет собой ранее обязательный пакет бумажных документов (Договор,  Акт и Свидетельство о поверке).",
          "Несмотря на то, что бумажные документы носят теперь только информационный характер, мы все же советуем регистрировать результаты поверки в «Концессии Водоснабжения» на случай сбоев в системе Аршин. По вашему желанию мы сделаем это за вас, если только вы проживаете в частном секторе или являетесь абонентом многоквартирного дома, имеющим прямой договор с ООО «Концессия Водоснабжения».",
          "Мы быстро примем и обработаем вашу заявку, проконтролируем внесение записи в ФГИС Аршин, а при необходимости окажем содействие в регистрации результатов поверки в ООО «Концессия Водоснабжения».",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "6",
    location: "poverka-priborov-ucheta-vodi-krasnoarmeiskii",
    name: "Поверка приборов учета воды в Красноармейском районе Волгограда",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Новокомунал – оперативная поверка счетчиков воды в Красноармейском районе Волгограда",
        type: "text",
      },
      {
        content:
          "«Поверка и замена счетчиков воды в Волгограде – быстро, качественно, с гарантией» − все это о компании Новокомунал, для которой Красноармейский р-н – приоритетное направление исполнения заявок. Среди наших ключевых преимуществ:",
        type: "text",
      },
      {
        content: "•	прием и обработка заявок в режиме 24/7;",
        type: "text",
      },
      {
        content:
          "•	предоставление услуги «срочный заказ» с выездом в день обращения;",
        type: "text",
      },
      {
        content: "•	скидки льготникам и при подаче коллективной заявки;",
        type: "text",
      },
      {
        content: "•	полное удовлетворение требований клиента и защита его прав.",
        type: "text",
      },
      {
        content:
          "Если результаты поверки огорчат вас необходимостью замены счетчика, то по вашему желанию мастер-метролог проведет его оперативную замену с выдачей полного пакета документов.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1f",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2f",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3f",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1f",
        question:
          "Для чего нужна поверка счетчиков воды в Красноармейском Волгограда?",
        answer: [
          'Собственники жилья давно оценили преимущества квартирных водосчетчиков, сократив с их помощью в 2-3  раза свои платежи за воду. Но, устанавливая водосчетчики, нельзя руководствоваться принципом "установил и забыл", а необходимо периодически проводить его обслуживание и поверку. Всё дело в том, водопроводная вода не является идеальной, а содержит в себе химически активные реагенты и различного рода инородные твёрдые частицы. В результате, наблюдается забивание фильтра и преждевременный износ деталей водосчетчика. В конечном итоге, все это может привести и приводит к ухудшению точности измерений, вплоть до достижения недопустимых значений. Поэтому необходимо периодически проверять текущую точность работы водосчетчика, что и делается с помощью его поверки− проведения технических действий, направленных на определение соответствия текущих эксплуатационных характеристик счетчика метрологическим требованиям.',
        ],
      },
      {
        key: "2f",
        question:
          "Когда должна проводиться поверка счетчиков воды в Красноармейском Волгограда?",
        answer: [
          "Поверка счетчиков воды проводится в плановом и внеплановом порядке. Плановая поверка проводится раз в 4-6 лет в соответствии с межповерочным интервалом (МИ), устанавливаемым заводом-изготовителем.",
          "МИ−период времени, в течение которого производителем гарантируется приемлемая точность измерений. Во время действия МИ расчёт оплаты проводится по показаниям счетчика.",
          "МИ приборов отечественного производства составляет 4-6 лет, в то время как лучшие европейские модели имеют МИ до 12 и более лет. В настоящее время последние модели российских производителей имеют 6-ти летний МИ, причём это относится и к водосчетчикам горячей воды.",
          "Возможны случаи, когда вам придётся поверять счетчик и до окончания МИ. Наиболее частый из них − явно ложные показания ИПУ по причине заводского брака, неточности монтажа, тяжёлых условий эксплуатации и т.д. Также внеплановая поверка требуется при утере документов на ИПУ, срыве пломб, нарушении поверочного клейма, после длительного отсутствия воды в системе.",
          "Внеплановая поверка водосчетчиков, как следует из самого названия, проводится при возникновении внештатных ситуаций. Например, в случае, когда водосчетчик начинает выдавать явно неправильные показания; при утере документов на прибор; при случайном срыве пломб или механическом повреждении счетчика; при появлении сторонних стуков или «тиканья» при работе счетчика; при длительном отсутствии воды в системе. При возникновении внештатных ситуаций вам необходимо сразу же сообщить об этом в вашу ресурсоснабжающую компанию и оперативно за свой счёт провести внеплановую поверку.",
        ],
      },
      {
        key: "3f",
        question:
          "Чем грозит просрочка поверки счетчиков воды в Красноармейском Волгограда?",
        answer: [
          "К сожалению, многие абоненты по своей халатности не соблюдают сроки проведения поверки. И ссылка на забывчивость в этом случае не срабатывает, так как сроки очередной поверки указываются в паспорте на прибор и в ЕПД.",
          "Если вы просрочите поверку, то готовьтесь выложить лишние деньги. Всё дело в том, что счетчик с просроченной поверкой признается вышедшим из строя или отсутствующим, и соответственно, его показания никем не признаются. За все время просрочки, начиная с первого числа месяца, следующего за месяцем окончания МИ, начисления за воду, согласно п.59 и 60 Постановления Правительства РФ №354 от 6 мая 2011 г вам будут проводить в следующем порядке:",
          "1. первый месяц просрочки −по среднемесячным показаниям за последние полгода;",
          "2. начиная со 2- го месяца просрочки−по нормативам потребления Волгоградской области с повышающим коэффициентом 1,5.",
        ],
      },
      {
        key: "4f",
        question:
          "Кто должен проводить поверку счетчиков воды в Красноармейском Волгограда?",
        answer: [
          "Все участники водоснабжения (ресурсоснабжающие организации, управляющие компании, собственники жилья) заинтересованы в объективности результатов поверки, поэтому государство допускает к её проведению исполнителей, обладающих соответствующим опытом, квалификацией и метрологическим оборудованием. Соответствие названным требованиям подтверждается аккредитацией в Росаккредитации. Всё другие (неаккредитованные) юридические и физические лица такого права не имеют и результаты их поверки не принимаются к сведению и учёту.",
          "Если вы воспользуйтесь услугами неаккредитованного исполнителя, то вам откажут в регистрации результатов поверки и придётся вновь проводить ее, опять же, за свои деньги.",
        ],
      },
      {
        key: "5f",
        question:
          "Как проводится поверка счетчиков воды в Красноармейском Волгограда?",
        answer: [
          "Поверку водосчетчиков можно провести на дому или в метрологической лаборатории. Поверка в лаборатории требует от абонента постоянного участия в течение нескольких дней, что согласитесь, не очень удобно. Поверка же на дому проводится всего за какие-то 30-60 минут и все участие абонента (или его представителя) сводится к подаче заявки и присутствию в квартире (доме) на время проведения поверки.",
          "Поверка на дому проводится с помощью переносного поверочного прибора (УПСЖ 5ПМ) через сравнение его показаний с показаниями счетчика.  Для достижения большей точности, измерения проводятся несколько раз при разных скоростях потока воды.",
          "Если разница в показаниях находится в пределах 3%, то водосчетчик признается исправным и годным для дальнейшей работы, в противном случае− подлежит демонтажу и замене.",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "7",
    location: "poverka-priborov-ucheta-vodi-krasnooktyabrskii",
    name: "Поверка приборов учета воды в Краснооктябрьском районе Волгограда",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Новокомунал – ваш лучший партнер по поверке счётчиков воды в Краснооктябрьском районе Волгограда",
        type: "text",
      },
      {
        content:
          "На рынке услуг ЖКХ достаточно мошеннических компаний, не имеющих прав на оказание лицензированных услуг. Чтобы не стать очередной жертвой мошенников, воспользуйтесь услугами нашей специализированной компании Новокомунал.",
        type: "text",
      },
      {
        content:
          "Мы быстро примем и обработаем вашу заявку, проконтролируем внесение записи в ФГИС Аршин, а при необходимости окажем содействие в регистрации результатов поверки в ООО «Концессия Водоснабжения».",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1g",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2g",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3g",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1g",
        question:
          "Зачем нужна поверка счётчиков воды в Краснооктябрьском районе Волгограда?",
        answer: [
          "Квартирные водомеры находятся под ежедневным воздействием далеко не идеальной по составу воды и постепенно изнашиваются, что приводит к ухудшению точности показаний. Как показывает практика, почти половина всех квартирных водомеров уже через несколько лет работы перестают давать точные показания. Для контроля точности измерений водомеров и проводится поверка – процедура подтверждения их соответствия метрологическим требованиям.",
        ],
      },
      {
        key: "2g",
        question:
          "Когда должна проводиться поверка счётчиков воды в Краснооктябрьском районе Волгограда?",
        answer: [
          "Плановая поверка квартирных счетчиков воды является обязательной процедурой (№ 102-ФЗ от 26.06.2008г. «Об обеспечении единства измерений» и Постановление Правительства Российской Федерации № 354 от 06.05.2011г.) и проводится по окончании межповерочного интервала (МИ).",
          "МИ – одна из главных эксплуатационных характеристик водомера, срок, в течение которого показания счетчика принимаются как достоверные всеми участниками процесса водоснабжения.",
          "МИ отечественных счетчиков холодной воды составляет 6 лет, а для счетчиков горячей воды он составляет 4 года. Такое расхождение по величине объясняется более интенсивным негативным воздействием горячей воды на водомер. В последние годы, благодаря использованию современных термостойких материалов, на рынке появляется все больше моделей счетчиков ГВС с 6-ти летним МИ.",
          "отсчет МИ проводится с даты заводской поверки, а никак не с даты его продажи или ввода в эксплуатацию.",
          "Может так случиться, что из-за заводского брака, неправильного монтажа или по другим причинам счетчик начнет сильно «завирать» задолго до окончания МИ. При обнаружении этого вам необходимо провести внеплановую досрочную поверку вашего водомера. Также досрочная поверка должна проводиться при утрате документов на счетчик, при срыве пломб, после длительного отсутствия воды в системе, при случайном повреждении счетчика.",
          "При возникновении внештатной ситуации вы должны незамедлительно сообщить об этом в вашу ресурсоснабжающую организацию и координировать свои действия с ней. Все затраты на проведение плановой/внеплановой поверки возлагаются на абонентов.",
        ],
      },
      {
        key: "3g",
        question:
          "Чем грозит просрочка поверка счётчиков воды в Краснооктябрьском районе Волгограда?",
        answer: [
          "Вы обязаны самостоятельно контролировать сроки проведения поверки, ее просрочка целиком на вашей совести и за это последует наказание рублем.",
          "Водомер с просроченным МИ считается отсутствующим и в соответствии с п.59 и 60 Постановления Правительства РФ №354 от 6 мая 2011 г вам первый месяц просрочки придется платить по среднемесячным показаниям счетчика, начиная со второго – по нормативам с применением повышающего коэффициента 1,5. Учитывая, что нормативы, как минимум, в 2 раза превышают фактическое потребление воды, то вы заплатите, как минимум, в 3 раза больше, чем по счетчику.",
        ],
      },
      {
        key: "4g",
        question:
          "Как проводится поверка счётчиков воды в Краснооктябрьском районе Волгограда?",
        answer: [
          "Поверку квартирного счетчика воды можно провести на лабораторном стенде, но для этого вам необходимо снять и доставить счетчик в лабораторию, а после поверки, выполнить все действия в обратном порядке. Демонтаж/ монтаж счетчика − достаточно хлопотное мероприятие, требующее согласования с управляющей организацией. Добавьте к этому транспортные расходы и томительное ожидание  результатов − в конечном итоге поверка в метрологической лаборатории выливается в целое многодневное мероприятие, требующее вашего постоянного участия. По этой причине основным вариантом поверки в Краснооктябрьском районе Волгограда является сегодня поверка на дому без снятия счетчика.",
          "Поверка на дому привлекает высокой скоростью проведения − не более 30-60 минут на один счетчик. При этом вы вправе сами выбрать удобный для вас день визита метролога.",
          "Поверка без снятия проводится с помощью переносного стенда (УПСЖ 5ПМ),  которую подключают к линии поверяемого счетчика. Открытием крана проливают через установку несколько литров воды и сверяют её показания с показаниями счетчика. Измерения повторяют на других скоростях потока, а затем определяют среднее отклонение показаний счетчика от показаний эталонной установки. Допустимым считается 3% отклонение, при большем отклонений счетчик подлежит замене",
        ],
      },
      {
        key: "5g",
        question:
          "Как регистрируются результаты поверки счётчиков воды в Краснооктябрьском районе Волгограда?",
        answer: [
          "По новым правилам от 24 сентября 2020 года регистрация результатов поверки проводится на сайте ФГИС Аршин через оформление соответствующей электронной записи метрологом аккредитованной компании. Эта запись должна быть внесена не позднее 40 дней со дня проведения поверки и является единственным и достаточным свидетельством ее проведения, а ранее выдававшийся пакет документов теперь не обязателен к получению.",
          "И пусть бумажные документы по новым правилам рассматриваются как просто носители информации, мы советуем регистрировать результаты поверки в ресурсоснабжающей организации на случай сбоев в системе Аршин. По вашему желанию мы сделаем это за вас, если только вы проживаете в частном секторе или являетесь абонентом многоквартирного дома, имеющим прямой договор с ООО «Концессия Водоснабжения».",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "8",
    location: "poverka-priborov-ucheta-vodi-traktorozavodskii",
    name: "Поверка приборов учета воды в Тракторозаводском районе Волгограда",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content: "Принцип действия бытового водомера",
        type: "text",
      },
      {
        content:
          "Бытовые (квартирные) водомеры−простые, доступные по цене и неприхотливые измерительные приборы механического принципа действия с относительно небольшим количеством деталей. Измерительным элементом счетчика выступает крыльчатка из высокопрочного пластика, вращающаяся под напором воды. Обороты крыльчатки передаются к измерительному барабану, расположенному в герметически отделенной измерительной части счетчика. Объем воды пропорционален оборотами крыльчатки и отображается в м3 на измерительном барабане.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1h",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2h",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3h",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1h",
        question:
          "Для чего проводится поверка счетчиков воды в Тракторозаводском районе Волгограда?",
        answer: [
          "Под действием воды лопасти крыльчатки постепенно изнашиваются и даже ломаются, на стенках проточной части появляются отложения, в некоторых случаях возможно искривление или смещение вала крыльчатки, сопровождаемое появлением посторонних стуков. В измерительной части счетчика может наблюдаться проскальзывание ведомой магнитной муфты, люфт в шестеренчатой передаче, заедание роликов счётного барабана. В результате суммарного действия вышеназванных факторов точность измерений водомера со временем может ухудшиться до недопустимых значений. На практике каждый второй счетчик не обеспечивает нужной точности измерений, проработав лишь половину заявленного срока эксплуатации. По этой причине и проводится поверка водомера−проверка его соответствия метрологическим требованиям.",
        ],
      },
      {
        key: "2h",
        question:
          "Когда проводится поверка счетчиков воды в Тракторозаводском районе Волгограда?",
        answer: [
          "Поверка счетчиков воды должна проводиться по окончании межповерочного интервала (МИ), устанавливаемого заводом-производителем. За счет более агрессивного влияния горячей воды на износ счетчика, МИ счетчиков ГВС составляет 4 года, в то время как МИ счетчиков ХВС составляет 6 лет. В последние годы отечественные производители, используя более износостойкие материалы, сумели подтянуть МИ счетчиков ГВС также до 6 лет. Последнее обстоятельство предоставляет больше удобств абоненту, позволяя одновременно проводить поверку сразу двух счетчиков (ХВС и ГВС).",
          "Важно знать, что отсчёт МИ проводится с даты заводской поверки, но никак не с даты покупки или установки счетчика. Например, для счетчика с 6-летним МИ, пролежавшим 2,5 года на складской полке, вам придется проводить его поверку уже через 3,5 года, а не через 6 лет.",
        ],
      },
      {
        key: "3h",
        question:
          "Какие штрафы за просрочку поверки счетчиков воды в Тракторозаводском районе Волгограда?",
        answer: [
          "В соответствии с действующим законодательством показания счетчика с просроченным МИ считаются недействительными, и за весь период просрочки вам придется платить по нормативам, причем начиная со 2-го месяца просрочки – с применением повышающего коэффициента 1,5. В переводе на реальные деньги просрочка поверки вам может обойтись вам в весьма ощутимую сумму.",
        ],
      },
      {
        key: "4h",
        question:
          "Как проводится поверка счетчиков воды в Тракторозаводском районе Волгограда?",
        answer: [
          "Различают лабораторную поверку водомеров и поверку на дому. Поверка в метрологической лаборатории подразумевает ваше непосредственное участие с выполнением целого ряда действий:",
          "1.	Обращение в управляющую компанию для вызова сантехника ",
          "2.	Приём сантехника, контроль его работы по снятию пломб, демонтажу счетчика и установки временных вставок",
          "3.	Доставку счетчика в лабораторию и ожидание в течение нескольких дней результатов поверки",
          "4.	Доставку проверенного счетчика домой",
          "5.	Вторичный вызов сантехника",
          "6.	Приём сантехника, контроль его работы по установке счетчика и пломб",
          "Как можно видеть, поверка в лаборатории растянута по времени, при этом вам придётся дважды отпрашиваться с работы и тратить деньги на поездки в лабораторию. Единственным, но ощутимым плюсом поверки в метрологической лаборатории является то, что там счетчик проходит разборку и детальное обследование, что практически гарантирует его безотказную работу в течение всего следующего межповерочного периода.",
          "В отличие от лабораторной поверки, поверка на дому требует минимального вашего участия, проводится всего в течение часа за один визит специалиста-метролога. При этом вы полностью гарантированы в сохранности ваших труб и пломб, что очень важно для домов с изношенной водопроводной системой.",
          "А если учесть сравнимость стоимости поверки в лаборатории со стоимостью поверки на дому, абсолютное большинство абонентов выбирает именно второй вариант поверки. Сама же процедура поверки на дому исключительно проста и включает следующие простые шаги:",
          "1.	Внешний осмотр счетчика на предмет отсутствия механических повреждений и целостности счетного механизма и пломб.",
          "2.	Подключение эталонного прибора к водопроводу.",
          "3.	Проведение испытаний и определение погрешности показаний счетчика через сравнение с эталоном.",
        ],
      },
      {
        key: "5h",
        question:
          "Как регистрируются результаты поверки счетчиков воды в Тракторозаводском районе Волгограда?",
        answer: [
          "На сегодняшний день с вступлением в действие новых правил от 24 сентября 2020 года результаты поверки оформляются в виде электронной записи на сайте ФГИС Аршин. Бумажные же документы утрачивают свою юридическую значимость и рассматриваются сегодня только как носители информации. Несмотря на это мы рекомендуем регистрировать результаты поверки в ресурсоснабжающей организации. По вашему желанию мы сделаем это за вас, если только вы проживаете в частном секторе или являетесь абонентом многоквартирного дома, имеющим прямой договор с ООО «Концессия Водоснабжения».",
        ],
      },
      {
        key: "6h",
        question:
          "Как правильно подобрать компанию для поверки счетчиков воды в Тракторозаводском районе Волгограда?",
        answer: [
          "Любая востребованная услуга порождает множество мошеннических компаний, желающих по легкому «срубить» денег и скрыться навсегда с горизонта доверчивого абонента. Такая ситуация наблюдается и вокруг поверки счетчиков воды, и чтобы не стать очередной жертвой мошенников, воспользуйтесь услугами нашей специализированной компании Новокомунал.",
          "Мы быстро примем и обработаем вашу заявку, проконтролируем внесение записи в ФГИС Аршин, а при необходимости окажем содействие в регистрации результатов поверки в ООО «Концессия Водоснабжения».",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "9",
    location: "poverka-priborov-ucheta-vodi-svetlii-yar",
    name: "Поверка приборов учета воды в Светлом Яре",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Светлом Яре вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Все измерительные приборы рано или поздно начинают терять свои начальные свойства, включая и увеличение погрешности измерений. Это в полной мере относится и к квартирным водомерам – расходомерам механического принципа действия, определяющим объемы потребленной воды через обороты вращения крыльчатки.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1i",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2i",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3i",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1i",
        question: "Для чего проводится поверка счетчиков воды в Светлом Яре?",
        answer: [
          "Все измерительные приборы рано или поздно начинают терять свои начальные свойства, включая и увеличение погрешности измерений. Это в полной мере относится и к квартирным водомерам – расходомерам механического принципа действия, определяющим объемы потребленной воды через обороты вращения крыльчатки.",
          "За время эксплуатации под негативным воздействием воды или внешним воздействием достаточно часто наблюдаются:",
          "•	механическое повреждение и разгерметизация счетчика;",
          "•	заклинивание крыльчатки и износ ее лопаток под действием абразивов, содержащихся в воде;",
          "•	известковый налет на стенках проточной части;",
          "•	искривление вала крыльчатки или смещение ее опор под действием гидроудара;",
          "•	нарушение магнитной связи;",
          "•	изменение геометрии лопатки и повышенный износ других деталей под действием сверхвысоких температур (для счетчиков ГВС).",
          "Под действием названных факторов и за счет естественного износа деталей погрешность измерений счетчика может выйти за допустимые пределы. Поэтому, с целью определения возможности дальнейшей эксплуатации водомера и проводится поверка – ряд технический действий по определению соответствия счетчика заявленным метрологическим характеристикам.",
        ],
      },
      {
        key: "2i",
        question: "Когда проводится поверка счетчиков воды в Светлом Яре?",
        answer: [
          "В соответствии с п. 80 Постановления Правительства №354 от 6 мая 2011 поверка счетчиков воды является обязательной процедурой, причем ответственность за соблюдением ее сроков и расходы на ее проведение возлагаются на абонента (собственника жилья).",
          "Плановая поверка водомеров в Светлом Яре должна проводиться при истечении межповерочного интервала (МИ) – периода времени, в течение которого производитель прибора гарантирует корректность его работы.  Как правило, МИ счетчиков горячей воды составляет 4 года, счетчиков холодной воды – 6 лет, а универсальных счетчиков – также 6 лет.",
          "МИ и дата первичной заводской поверки указываются в паспорте на прибор. Важно знать, что отсчет МИ проводится с даты заводской поверки, но никак не с даты покупки или установки водомера. Этот факт необходимо учитывать при покупке счетчика, чтобы не пришлось проводить поверку намного раньше паспортного значения МИ.",
          "Помимо паспорта на водомер, сроки  поверки вы можете контролировать по вашей платежной квитанции или на сайте Аршин.",
        ],
      },
      {
        key: "3i",
        question: "Чем грозит просрочка поверки счетчиков воды в Светлом Яре?",
        answer: [
          "По истечению МИ ИПУ считается вышедшим из строя, даже если продолжает исправно работать. Теперь его показания считаются недействительными, и в соответствии с п.59 и 60 Постановления Правительства РФ №354 от 6 мая 2011 г, начисления проводятся по региональным нормативам потребления, умноженным на 1,5. Учитывая «драконовскую» величину нормативов, при длительной просрочке вода для вас станет поистине «золотой».",
        ],
      },
      {
        key: "4i",
        question: "Как проводится поверка счетчиков воды в Светлом Яре?",
        answer: [
          "Поверка квартирных водомеров возможна двумя способами: с демонтажом счетчика и без его демонтажа. В первом случае снятый водомер доставляется в метрологическую лабораторию, где проходит всестороннее обследование и поверку. Если по результатам поверки водомер признается годным, то он доставляется домой и устанавливается на свое место.",
          "Данный вариант поверки не слишком удобен для абонентов именно из-за необходимости демонтажа водомера и решения целого ряда сопутствующих вопросов: необходимо дважды вызывать сантехника и оплачивать его услуги по демонтажу/монтажу водомера, дважды выезжать в лабораторию и в течение нескольких дней ожидать результатов поверки.",
          "Поверка же без снятия водомера не требует от абонента практически никаких усилий, не считая подачи заявки и часа времени на проведение поверочных работ. При этом нет необходимости согласования своих действий с управляющей компанией – вы можете провести поверку в любой удобный для себя день, включая и выходные.",
          "Поверка на дому  проводится с помощью эталонного прибора (УПСЖ 5ПМ)  Допустимо 3% расхождение показаний,  если же наблюдается большее расхождение, то водомер необходимо заменить.",
        ],
      },
      {
        key: "5i",
        question:
          "Кто имеет право проведения поверки счетчиков воды в Светлом Яре?",
        answer: [
          "Право поверки средств измерений имеют только аккредитованные компании, имеющие действующий статус аккредитации. Аккредитация проводится Госстандартом РФ и для получения Аттестата аккредитация компания должна удовлетворять строгим квалификационным требованиям, включая наличие штата сертифицированных специалистов и высокоточного метрологического оборудования.",
        ],
      },
      {
        key: "6i",
        question:
          "Как регистрируются результаты поверки счетчиков воды в Светлом Яре?",
        answer: [
          "После поверки на дому метролог обязан в течение 40 рабочих дней внести запись о ее результатах на сайт ФГИС Аршин, доступ к которому открыт для всех, включая и самого абонента. Введя номера своих счетчиков на странице «Сведения о результатах поверки средств измерений», каждый абонент может увидеть их текущее состояние.",
          "Несмотря на то, что бумажные документы носят теперь только информационный характер, мы все же советуем регистрировать результаты поверки в «Концессии Водоснабжения» на случай сбоев в системе Аршин. По вашему желанию мы сделаем это за вас, если только вы проживаете в частном секторе или являетесь абонентом многоквартирного дома, имеющим прямой договор с ООО «Концессия Водоснабжения».",
          "Мы быстро примем и обработаем вашу заявку, проконтролируем внесение записи в ФГИС Аршин, а при необходимости окажем содействие в регистрации результатов поверки в ООО «Концессия Водоснабжения».",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
  {
    key: "10",
    location: "poverka-priborov-ucheta-vodi-sovetskii",
    name: "Поверка приборов учета воды в Советском районе Волгограда",
    src: `${price1}`,
    price: ["От 400 руб."],
    form:
      "Заказать поверку счетчиков воды в Волгограде вы можете прямо здесь на сайте или позвонив нам по телефону",
    formsub:
      "Если у вас заканчивается межповерочный интервал ваших квартирных приборов учета, то спешите заказать поверку счетчиков воды в компании Новокомунал.",
    about: [
      {
        content:
          "Необходимость проведения периодической поверки квартирных водомеров закреплена Законом № 102-ФЗ от 26.06.2008 года «Об обеспечении единства измерений» и Постановлением Правительства РФ № 354 от 06.05.2011 года. Согласно этим документам, за весь период просрочки начисления за воду вам будут проводиться не по показаниям счетчика, который теперь считается вышедшим из строя или отсутствующим, а по нормативам, причем, начиная со второго месяца просрочки − с применением повышающего коэффициента 1,5. В реальных деньгах это выльется в сумму, в 2-3 раза большую, чем по показаниям счетчика.",
        type: "text",
      },
    ],
    documents: [
      {
        key: "1j",
        index: 0,
        src: `${act}`,
        title: "Акт сдачи-приемки выполненых работ",
      },
      {
        key: "2j",
        index: 1,
        src: `${dog1}`,
        title: "Договор возмездного оказания услуг",
      },
      {
        key: "3j",
        index: 2,
        src: `${sert}`,
        title: "Копия аккредитационного аттестата",
      },
    ],
    faq: [
      {
        key: "1j",
        question:
          "Для чего необходимо проводить поверку счетчиков воды в Советском  р-не Волгограда?",
        answer: [
          "Квартирные счетчики воды являются достаточно износоустойчивыми измерителями расхода, рассчитанные на долгий срок эксплуатации. Однако, ежедневно пропуская через себя далеко не идеальную по составу воду, квартирные водомеры постепенно изнашиваются, что неизбежно приводит к ухудшению точности измерений. Наглядный пример: известковый налет, годами отлагаясь на стенках проточной части, постепенно приводит к ее сужению, что, в свою очередь, вызывает увеличение скорости потока и как следствие − увеличение числа оборотов крыльчатки и выдачу завышенных показаний. Или обратный пример: заклинивание крыльчатки из-за попадания инородных частиц под ее ось приводит к замедлению ее вращения и счетчик начинает «врать» в меньшую сторону. Помимо названных, достаточно и других причин (гидроудары, высокие температуры, естественный износ деталей и т.д.) для ухудшения точности работы водомера, поэтому и необходимо периодически проводить поверку для определения его соответствия метрологическим требованиям.",
          "Необходимость проведения периодической поверки квартирных водомеров закреплена Законом № 102-ФЗ от 26.06.2008 года «Об обеспечении единства измерений» и Постановлением Правительства РФ № 354 от 06.05.2011 года. Согласно этим документам, за весь период просрочки начисления за воду вам будут проводиться не по показаниям счетчика, который теперь считается вышедшим из строя или отсутствующим, а по нормативам, причем, начиная со второго месяца просрочки − с применением повышающего коэффициента 1,5. В реальных деньгах это выльется в сумму, в 2-3 раза большую, чем по показаниям счетчика.",
        ],
      },
      {
        key: "2j",
        question:
          "Когда необходимо проводить поверку счетчиков воды в Советском  Волгограда?",
        answer: [
          "Прежде всего, необходимо различать плановую и внеплановую поверку водомеров. Плановая поверка проводится периодически, раз в несколько лет, при приближении окончания межповерочного интервала (МИ), устанавливаемого заводом-изготовителем. В течение действия МИ считается, что счетчик работает правильно и его показания считаются достоверными  и принимаются к учету ресурсоснабжающей организацией. МИ квартирных водомеров отечественных производителей в среднем составляет 4-6 лет. ",
          "Точное значение МИ и дата первичной заводской поверки приводятся в паспорте на прибор. Знание даты заводской поверки очень важно, так как именно с нее начинается отсчет МИ.",
          "Сроки проведения очередной плановой поверки вы можете контролировать с помощью паспорта на счетчик, вашего ЕПД, сайта ФГИС Аршин или просто позвонив в свою управляющую компанию.",
          "Редко, но встречаются ситуации, когда возникает необходимость проведения досрочной поверки. Например, если вы случайно повредите счетчик, непреднамеренно сорвете пломбы, повредите поверочное клеймо или утеряете паспорт на прибор, то вам необходимо сообщить о случившемся в ресурсоснабжающую организацию и оперативно провести поверку водомера. Внеплановая поверка также необходима после длительного нахождения счетчика в «сухом» состоянии.",
        ],
      },
      {
        key: "3j",
        question:
          "Как проводится поверка счетчиков воды в Советском р-не Волгограда?",
        answer: [
          "Поверку водосчетчика можно провести или в метрологической лаборатории, или на дому. В первом случае вам необходимо выполнить целый ряд шагов:",
          "1.	Вызвать сантехника управляющей компании для демонтажа счетчика и установки временной вставки.",
          "2.	Доставить счетчик в лабораторию.",
          "3.	В течение нескольких дней ожидать результатов поверки.",
          "4.	Забрать счетчик из лаборатории.",
          "5.	Вновь вызвать сантехника для установки и опломбирования поверенного счетчика.",
          "Как можно видеть, лабораторный вариант поверки достаточно длительный по времени и весьма хлопотный для абонента.",
          "Поверка на дому значительно более привлекательна, так как не связана с демонтажом счетчика и сопутствующими ему проблемами. Поверка на дому проводится с помощью мобильного поверочного прибора (ВПУ Энерго М, УПСЖ 3ПМ, Водоучет 2М), занимает не более часа времени всего за один визит метролога.",
        ],
      },
      {
        key: "4j",
        question:
          "Как регистрируются результаты поверки счетчиков воды в Советском  Волгограда?",
        answer: [
          "По завершении поверочных испытаний мастер-метролог должен в течение 40 рабочих дней внести электронную запись в систему Аршин, которая по новым правилам поверки является единственным и достаточным документальным свидетельством прохождения поверки. Получать пакет бумажных документов по закону теперь совсем необязательно, но мы все же рекомендуем требовать от метролога проставления отметки в паспорте на водосчетчик, выдачи квитанции об оплате и полного пакета документов для возможного представления в ресурсоснабжающую организацию.",
        ],
      },
      {
        key: "5j",
        question:
          "Как выбрать компанию по поверке счетчиков воды в Советском р-не Волгограда?",
        answer: [
          "Настоящим бичом современного рынка услуг ЖКХ является деятельность многочисленных компаний, не имеющих никакого права на их проведение и соответственно, не гарантирующих качество оказываемых услуг. Необходимо знать, что к поверке водомеров допускаются только аккредитованные компании с действующим статусом аккредитации.",
        ],
      },
      {
        key: "6j",
        question: "Что лучше: поверка или замена счетчиков воды в Волгограде?",
        answer: [
          "Постановка такого вопроса обусловлена тем, что уже по результатам первой поверки более половины всех отечественных водомеров признается непригодной для дальнейшей эксплуатации. Поэтому одни абоненты предпочитают сразу заменить счетчики на новые, другие – выбирают поверку, а уже в случае ее отрицательного заключения переходят к замене счетчика. Каждый из вариантов имеет свои «плюсы» и «минусы», а как поступить конкретно вам – это сугубо ваше решение.",
          "Если вам необходима поверка или замена , то чтобы не стать очередной жертвой мошенников, воспользуйтесь сертифицированными услугами компании Новокомунал. Мы принимаем заявки в режиме 24/7 и гарантируем высокое качество услуг по демократичным ценам.",
        ],
      },
    ],
    pricelist: [
      {
        key: "1",
        name: "Поверка счетчика в квартире",
        price: "400 руб/шт",
      },
      {
        key: "2",
        name:
          "Замена счётчика в квартире с новым счётчика (1 счётчик в квартире ½ (дюйма))",
        price: "2250 рублей / 2500 рублей",
      },
      {
        key: "3",
        name: "Замена счётчика в квартире счётчик абонента (½ (дюйма))",
        price: "1200 руб/шт",
      },
      {
        key: "4",
        name: "Поверка счётчика по гарантии (Счётчик рабочий)",
        price: "500 руб/шт",
      },
      {
        key: "5",
        name:
          "Консультация на адресе/ ранний вызов/ отсутствие доступа к счётчику (без проведения работ с счётчиком) ",
        price: "300 руб/шт",
      },
      {
        key: "6",
        name: "Поверка в частном секторе	(в доме)",
        price: "800 руб/шт",
      },
      {
        key: "7",
        name: "Поверка в частном секторе	(в колодце)",
        price: "1000 руб/шт",
      },
      {
        key: "8",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик абонента) (½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "9",
        name:
          "Замена счетчика частный сектор  (в доме, счётчик входит в стоимость) (½ дюйма, ¾ дюйма)",
        price: "2500 руб/3000 руб",
      },
      {
        key: "10",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик жильца)	(½ дюйма, ¾ дюйма)",
        price: "1500 руб/1800 руб",
      },
      {
        key: "11",
        name:
          "Замена счётчик в частном дом (в колодце, счётчик входит в стоимость)	(½ дюйма, ¾ дюйма)",
        price: "3000 руб/3300 руб",
      },
      {
        key: "12",
        name:
          "Замена счётчика или устранение течи по гарантии	(½ дюйма, ¾ дюйма)",
        price: "0 руб/шт",
      },
      {
        key: "13",
        name:
          "Поверка счётчика по гарантии  (счётчик оказался пригодный к эксплуатации)",
        price: "800 руб/шт",
      },
      {
        key: "14",
        name: "Замена ИПУ юр. лицам  (¾ дюйма / ½ дюйма)",
        price: "3000 руб/2500 руб",
      },
      {
        key: "15",
        name: "Замена ИПУ юр.лицам со своим ИПУ (¾ дюйма / ½ дюйма)",
        price: "1500 руб/2000 руб",
      },
      {
        key: "16",
        name: "Поверка ИПУ юр. лицам по гарантии (ИПУ рабочий)",
        price: "2000 руб/шт",
      },
    ],
  },
];
