import React from "react";
import "./Gallery.css";
import { GalleryContext } from "../../context/GalleryContext";

function Gallery(props) {
  const gallery = React.useContext(GalleryContext);
  const [slideStart, setSlideStart] = React.useState(0);
  const [slideEnd, setSlideEnd] = React.useState(
    props.dimensions.width <= 768 ? gallery.length : 4
  );

  function slidePlus() {
    if (slideEnd >= gallery.length - 1) {
      setSlideStart(0);
      setSlideEnd(4);
    } else {
      setSlideStart(slideStart + 4);
      setSlideEnd(slideEnd + 4);
    }
  }
  function slideMinus() {
    if (slideStart <= 1) {
      setSlideStart(8);
      setSlideEnd(12);
    } else {
      setSlideStart(slideStart - 4);
      setSlideEnd(slideEnd - 4);
    }
  }
  function isOpen(e) {
    props.setOpenPopupImg(true);
    props.setPopupImg(e.target.src);
  }
  return (
    <section className="gallery">
      {props.children}
      <div className="gallery__container">
        <button
          type="button"
          className="button__arrow button__arrow_left"
          onClick={slideMinus}
        ></button>
        <div className="gallery__box">
          {gallery.slice(slideStart, slideEnd).map((slide) => (
            <img
              key={`${slide.key}g`}
              id={slide.key}
              onClick={(e) => {
                isOpen(e);
                props.setPopupImg(slide.index);
                props.setGalleryImg(gallery);
              }}
              className="gallery__img"
              src={slide.src}
              alt="foto"
            />
          ))}
        </div>
        <button
          type="button"
          className="button__arrow button__arrow_right"
          onClick={slidePlus}
        ></button>
      </div>
    </section>
  );
}

export default Gallery;
