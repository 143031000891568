import React from "react";
import gallery1 from "../images/gallery/1.jpg";
import gallery2 from "../images/gallery/2.jpg";
import gallery3 from "../images/gallery/3.jpg";
import gallery4 from "../images/gallery/4.jpg";
import gallery5 from "../images/gallery/5.jpg";
import gallery6 from "../images/gallery/6.jpg";
import gallery7 from "../images/gallery/7.jpg";
import gallery8 from "../images/gallery/8.jpg";
import gallery9 from "../images/gallery/9.jpg";
import gallery10 from "../images/gallery/10.jpg";
import gallery11 from "../images/gallery/11.jpg";
import gallery12 from "../images/gallery/12.jpeg";
import gallery13 from "../images/gallery/13.jpg";
import gallery14 from "../images/gallery/14.jpg";
import gallery15 from "../images/gallery/15.jpg";
import gallery16 from "../images/gallery/16.jpg";

export const GalleryContext = React.createContext();

export const gallery = [
  {
    key: "1",
    index: 0,
    src: `${gallery1}`,
  },
  {
    key: "2",
    index: 1,
    src: `${gallery2}`,
  },
  {
    key: "3",
    index: 2,
    src: `${gallery3}`,
  },
  {
    key: "4",
    index: 3,
    src: `${gallery4}`,
  },
  {
    key: "5",
    index: 4,
    src: `${gallery5}`,
  },
  {
    key: "6",
    index: 5,
    src: `${gallery6}`,
  },
  {
    key: "7",
    index: 6,
    src: `${gallery7}`,
  },
  {
    key: "8",
    index: 7,
    src: `${gallery8}`,
  },
  {
    key: "9",
    index: 8,
    src: `${gallery9}`,
  },
  {
    key: "10",
    index: 9,
    src: `${gallery10}`,
  },
  {
    key: "11",
    index: 10,
    src: `${gallery11}`,
  },
  {
    key: "12",
    index: 11,
    src: `${gallery12}`,
  },
  {
    key: "13",
    index: 12,
    src: `${gallery13}`,
  },
  {
    key: "14",
    index: 13,
    src: `${gallery14}`,
  },
  {
    key: "15",
    index: 14,
    src: `${gallery15}`,
  },
  {
    key: "16",
    index: 15,
    src: `${gallery16}`,
  },
];
