import "./Seo.css";
import React from "react";
import { SeoContext, seos } from "../../context/SeoContext";
import Questions from "../QuestionsSeo/Questions";
import { useParams, Link } from "react-router-dom";
import Form from "../Form/Form";
import { Helmet } from "react-helmet";

function Seo(props) {
  const { seo } = useParams();
  const [seoName, setSeoName] = React.useState("");
  const [seoPrice, setSeoPrice] = React.useState("");
  const [seoAbout, setSeoAbout] = React.useState([]);
  React.useEffect(() => {
    setSeoName(seos.find((p) => p.location === seo).name);
    setSeoPrice(seos.find((p) => p.location === seo).price);
    setSeoAbout(seos.find((p) => p.location === seo).about);
  }, [seo]);
  return (
    <section className="service">
      <Helmet>
        <meta name="description" content={seoName} />
        <title>{seoName}</title>
        <link rel="canonical" href="https://novokomunal.ru/" />
        <meta name="robots" content="index,follow" />
        <meta name="keywords" content="" />
        <meta property="og:title" content={seoName} />
        <meta property="og:description" content={seoName} />
      </Helmet>
      <div className="service__title-box">
        <div className="service__link-box">
          <Link className="service__link" to="/">
            Главная/
          </Link>
          <Link className="service__link" to="/services">
            Услуги/
          </Link>
          <p className="service__link">{seoName}</p>
        </div>
        <h2 className="service__title">{seoName}</h2>
      </div>
      <div className="service_box">
        {seoAbout.map((s) =>
          s.type === "title" ? (
            <h2
              key={`${seoAbout.indexOf(s)}titleseo`}
              className="service__subtitle"
            >
              {s.content}
            </h2>
          ) : s.type === "text" ? (
            <p key={`${seoAbout.indexOf(s)}textseo`} className="service__text">
              {s.content}
            </p>
          ) : s.type === "img" ? (
            <img
              key={`${seoAbout.indexOf(s)}imgseo`}
              className="service__img"
              src={s.content}
            />
          ) : null
        )}
      </div>
      <div className="service__price-box">
        {seos
          .find((p) => p.location === seo)
          .pricelist.map((p) => (
            <div
              key={`${seos
                .find((p) => p.location === seo)
                .pricelist.indexOf(p)}priselistseo`}
              className="service__price-container"
            >
              <img
                className={`${props.classNameImg} service__price-img`}
                src={seos.find((p) => p.location === seo).src}
                alt="Услуга"
              />
              <p className="service__price-name">{p.name}</p>
              <p className="service__price-price">{p.price}</p>
              <button
                type="button"
                className={`${props.classNameButton} section__button section__button_blue`}
                onClick={() => props.setOpenCall(true)}
              >
                заказать
              </button>
            </div>
          ))}
      </div>
      <Form
        onSubmit={props.onSubmit}
        setThanks={props.setThanks}
        class="services"
        title={seos.find((p) => p.location === seo).form}
        subtitle={seos.find((p) => p.location === seo).formsub}
        button="Заказать"
      />
      <SeoContext.Provider value={seos}>
        <Questions
          pathname="service"
          setOpenQuestion={props.setOpenQuestion}
          serviceKey={seo}
        >
          <h2 className="section__title">Часто задаваемые вопросы</h2>
        </Questions>
      </SeoContext.Provider>
    </section>
  );
}

export default Seo;
