import React from "react";
import up from "../../images/icon/up.svg";
import down from "../../images/icon/down.svg";
import { SeoContext } from "../../context/SeoContext";
import { useLocation } from "react-router-dom";

function Questions(props) {
  const { pathname } = useLocation();
  const seos = React.useContext(SeoContext);
  const [isOpen, setOpen] = React.useState(false);
  const [isId, setId] = React.useState("0");

  return (
    <section className="questions" id="questions">
      {props.children}
      <div className="questions__main-box">
        {seos
          .find((p) => p.location === props.serviceKey)
          .faq.map((question) => (
            <div
              key={`${question.key}seoq`}
              className="questions__box"
              id={question.key}
              onClick={() => {
                if (isOpen && isId !== question.key) {
                  setOpen(true);
                  setId(question.key);
                } else {
                  setOpen(!isOpen);
                  setId(question.key);
                }
              }}
            >
              <div className="questions__container">
                <p className="questions__question">{question.question}</p>
                <img
                  className="questions__icon"
                  src={isOpen & (question.key === isId) ? up : down}
                  alt="icon"
                />
              </div>
              {question.answer.map((a) => (
                <p
                  key={`${question.answer.indexOf(a)}sepq2`}
                  className={
                    isOpen && question.key === isId
                      ? "questions__answer questions__answer_active"
                      : "questions__answer"
                  }
                >
                  {a}
                </p>
              ))}
            </div>
          ))}

        <div className="questions__info">
          <p className="questions__text">
            Не нашли ответа? Заполните форму, а мы свяжемся с Вами в ближайшее
            время и ответим на все вопросы
          </p>
          <button
            className="section__button section__button_blue"
            onClick={() => props.setOpenQuestion(true)}
          >
            Задать вопрос
          </button>
        </div>
      </div>
    </section>
  );
}

export default Questions;
